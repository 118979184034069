var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"596","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c('div',{staticClass:"pa-4 pt-1"},[_c('v-card-title',{staticClass:"pb-4"},[_vm._v(" Применить "+_vm._s(_vm.name)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('SelectSections',{staticClass:"mx-4",attrs:{"persistent-hint":"","hint":"Оставьте пустым, если нужны все разделы","delay":0},on:{"input":_vm.updateSelectedSections}}),_c('v-card-text',[_c('v-data-table',{staticClass:"custom-table",attrs:{"headers":_vm.MODAL_VIEW_TABLE_HEADERS,"items":_vm.filteredParams,"items-per-page":-1,"mobile-breakpoint":0,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.param.name",fn:function({ header }){return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"item.param.name",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(item.name))]),(item.value)?_c('div',{class:[
                  'item-value',
                  {
                    problem:
                      item.notPresentOnAnySelectedEl &&
                      !item.caCreaten &&
                      _vm.getParamCreatingStatus(_vm.eirId, item.name) !== 3,
                  },
                ]},[_vm._v(" "+_vm._s(_vm.getItemDescription(item))+" ")]):_vm._e()]}},{key:"item.options",fn:function({ item }){return [_c('OptionsSlider',{key:item.name,staticClass:"option-slider",attrs:{"param":item,"items":_vm.params,"disabled":item.notPresentOnAnySelectedEl &&
                  _vm.getParamCreatingStatus(_vm.eirId, item.name) !== 3},on:{"update:items":function($event){_vm.params=$event}}})]}},{key:"item.param.notPresentOnAnySelectedEl",fn:function({ item }){return [(
                  item.notPresentOnAnySelectedEl &&
                  _vm.getParamCreatingStatus(_vm.eirId, item.name) !== 3
                )?[(item.canCreate)?_c('v-btn',{attrs:{"color":_vm.getParamCreatingStatus(_vm.eirId, item.name) === 0
                      ? 'error'
                      : 'warning',"outlined":"","disabled":item.readOnly || _vm.isCreating,"loading":_vm.getParamCreatingStatus(_vm.eirId, item.name) === 2},on:{"click":function($event){return _vm.create(item)}}},[_vm._v(" Создать ")]):_c('v-btn',{attrs:{"color":_vm.getParamCreatingStatus(_vm.eirId, item.name) === 0
                      ? 'error'
                      : 'warning',"outlined":"","disabled":item.readOnly,"title":"Перейти на страницу редактирования параметров","to":`/eir-sets/${_vm.setId}?tab=1`}},[_vm._v(" Заполнить ")])]:_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"disabled":item.readOnly,"color":"primary"}},[_vm._v(" mdi-check-all ")])],1)]}}])})],1),_c('SaveCloseButtons',{staticClass:"mx-2",attrs:{"disabled":_vm.isLoading,"disable-btn-save":""},on:{"close":function($event){_vm.dialog = false}}},[_c('v-btn',{staticClass:"ml-2 elevation-10 flex",attrs:{"color":"primary","type":"submit","disabled":_vm.isLoading || !_vm.filteredParams.length || _vm.paramsIsNotPresent,"x-large":""}},[_vm._v(" Записать на "+_vm._s(_vm.btnText)+" элементы ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }