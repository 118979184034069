<template>
  <v-dialog
    v-model="dialog"
    width="476"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" color="primary" indeterminate />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            {{
              formMode === 'create'
                ? 'Добавление простого параметра'
                : 'Редактирование параметра'
            }}
            <v-spacer />
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model.trim="fields.name"
              label="Наименование*"
              :rules="[rules.required]"
              outlined
              dense
            />

            <v-text-field
              v-model.trim="fields.symbol"
              label="Краткое наименование"
              outlined
              dense
            />

            <v-text-field
              v-model.trim="fields.definition"
              label="Описание / Определение"
              outlined
              dense
            />

            <v-autocomplete
              v-model="fields.unit_type"
              label="Единицы измерения"
              :items="unitTypeItems"
              item-value="key"
              item-text="text"
              outlined
              dense
            />

            <v-select
              v-model="fields.param_type"
              label="Тип параметра*"
              :items="PARAM_TYPE_ITEMS"
              :rules="[rules.required]"
              outlined
              dense
            />

            <v-text-field
              v-model.trim="fields.check_regexp"
              label="Валидация"
              hint="Регулярное выражение"
              outlined
              dense
            />

            <v-checkbox
              v-model="fields.is_required"
              label="Обязателен к заполнению"
              outlined
              dense
            />

            <div class="mt-6">*&nbsp;&mdash; обязательно</div>
          </v-card-text>

          <SaveCloseButtons :disabled="isLoading" @close="dialog = false" />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import rules from '@/mixins/rules'
import { DisplayUnitType, PARAM_TYPE_ITEMS } from '@/constants'

import Parameter from '@/models/Parameter'
import SaveCloseButtons from '@/components/SaveCloseButtons'

export default {
  name: 'ModalParameter',
  components: { SaveCloseButtons },
  mixins: [rules],
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: false,
    formMode: 'create',
    fields: Parameter.getModel(),
    unitTypeItems: Object.entries(DisplayUnitType)
      .map(([key, text]) => ({
        key,
        text,
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    PARAM_TYPE_ITEMS,
  }),
  computed: mapGetters('parameters', ['maxSortingPriority']),
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    ...mapActions('parameters', [
      'fetchParams',
      'createParameter',
      'updateParameter',
    ]),

    show(data = {}, formMode = 'create') {
      this.formMode = formMode
      if (formMode === 'update') {
        this.fields = { ...data }
      }
      this.dialog = true
    },

    async submit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.isLoading = true
        if (this.formMode === 'create') {
          this.fields.sorting_priority = this.maxSortingPriority + 1
          delete this.fields.id
          const object = {
            ...this.fields,
            eir_set: this.$route.params.setId,
          }
          await this.createParameter(object)
          await this.$logActionEvent('Добавлен параметр')
        } else {
          await this.updateParameter(this.fields)
          await this.$logActionEvent('Параметр отредактирован')
        }
        await this.fetchParams({
          filters: { eir_set: +this.$route.params.setId },
        })

        this.isLoading = false
        this.$emit('updated')
        this.dialog = false
      }
    },
  },
}
</script>
