<template>
  <div>
    <div class="slider-value">{{ text }}</div>
    <v-slider
      v-model="value"
      class="slider mr-2"
      color="primary"
      ticks="always"
      tick-size="3"
      step="1"
      :max="2"
      hide-details
      dense
      :disabled="param.readOnly || disabled"
      :readonly="param.readOnly || disabled"
      @input="changeParamFields()"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'OptionsSlider',
  props: {
    param: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    paramModel: {},
    labels: ['Не записывать', 'Записать', 'Перезаписать'],
    value: 1,
  }),
  computed: {
    text: (vm) => vm.labels[vm.value],
  },
  watch: {
    'param.readOnly': {
      handler(newVal) {
        if (!newVal) return

        this.value = 0
        this.changeParamFields()
      },
      immediate: true,
    },
    'param.writeDown': {
      handler(newVal) {
        if (newVal || this.value === 0 || this.paramModel.writeDown === newVal)
          return

        this.value = 0
        this.changeParamFields()
      },
      immediate: true,
    },
  },
  methods: {
    changeParamFields(value = this.value) {
      const writeDown = value === 1 || value === 2
      const overwriteExistingValue = value === 2

      this.paramModel = { ...this.param, overwriteExistingValue, writeDown }

      const index = this.items.findIndex(
        (param) => this.param.name === param.name
      )
      const items = cloneDeep(this.items)
      items[index] = this.paramModel

      this.$emit('change', { overwriteExistingValue, writeDown })
      this.$emit('update:items', items)
    },
  },
}
</script>

<style scoped lang="scss">
.slider-value {
  height: 21px;
  line-height: 1;
  font-size: 75%;
  margin-bottom: -8px;
}

.slider::v-deep .v-slider__tick {
  border-radius: 100%;
  background-color: #00afa1;
}
</style>
