import Model from '@/models/Model'

export default class Parameter extends Model {
  static model = {
    name: '',
    definition: '',
    eir_set: '',
    symbol: '',
    unit_type: '',
    is_required: false,
    check_regexp: '',
    sorting_priority: 0,
    param_type: '',
  }
}
