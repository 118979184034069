export function checkIfReadonly(param) {
  return (
    (param?.sapr_parameters ?? []).find(
      ({ name }) => name === 'REVIT_IS_READONLY'
    )?.value ?? false
  )
}

export function checkIfCanCreateParam(param) {
  const saprFields = param.sapr_parameters ?? []
  const fieldsKeys = ['REVIT_TYPE', 'REVIT_DATA_TYPE', 'REVIT_PARAMETER_GROUP']

  return fieldsKeys.every(
    (key) =>
      saprFields?.findIndex((field) => field.name === key && field.value) !== -1
  )
}

export function checkIfCustomReadonly(param) {
  return (
    param.sapr_parameters?.findIndex(
      (field) => field.name === 'REVIT_IS_READONLY' && field.value === true
    ) !== -1
  )
}

export function checkIfShared(param) {
  return (
    param.sapr_parameters?.findIndex(
      (field) => field.name === 'REVIT_IS_SHARED' && field.value === true
    ) !== -1
  )
}
