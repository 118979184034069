<template>
  <v-dialog
    v-model="dialog"
    width="476"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-card-title class="pb-4">
          Выбор шаблона фильтра
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="templateId"
            hide-details
            outlined
            :loading="isLoading || isDeleting"
            dense
            :rules="[rules.required]"
            label="Название шаблона*"
            item-text="name"
            item-value="id"
            :return-object="false"
            :items="templatesList"
          >
            <template #item="{ item }">
              <div class="d-flex align-center">
                <div class="pr-4">{{ item.name }}</div>
                <v-btn
                  small
                  color="error"
                  icon
                  title="Удалить шаблон"
                  @click.stop="deleteTemplate(item.id)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-select>
        </v-card-text>

        <SaveCloseButtons
          :disabled="isLoading"
          btn-text-save="Выбрать"
          @close="dialog = false"
        />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SaveCloseButtons from '@/components/SaveCloseButtons'

import rules from '@/mixins/rules'

export default {
  name: 'ModalSelectTemplate',
  components: { SaveCloseButtons },
  mixins: [rules],
  data: () => ({
    valid: true,
    templateId: '',
    dialog: false,
  }),
  computed: {
    ...mapState('filters', [
      'templatesList',
      'listStatus',
      'templateFilterStatus',
    ]),
    isLoading: (vm) => vm.listStatus === 2,
    isDeleting: (vm) => vm.templateFilterStatus === 2,
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
    isDeleting(newValue) {
      if (!newValue) this.getTemplateFilterList()
    },
  },
  methods: {
    ...mapActions('filters', ['getTemplateFilterList', 'removeTemplateFilter']),
    show() {
      this.dialog = true
      this.getTemplateFilterList()
    },
    async submit() {
      if (!this.$refs.form.validate()) return

      this.$emit('select', this.templateId)
      this.dialog = false
    },
    deleteTemplate(templateId) {
      if (!confirm('Удалить шаблон?')) return

      this.removeTemplateFilter(templateId)
    },
  },
}
</script>
