<script setup>
import { reactive, ref, defineExpose, watch } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { useStore } from '@/store'
import { rulesObject } from '@/mixins/rules'
import { DEFAULT_NOTIFY_SNACKBAR } from '@/store/constants'
import SaveCloseButtons from '@/components/SaveCloseButtons.vue'

defineExpose({ show })

const store = useStore()
const valid = ref(false)
const dialog = ref(false)
const isLoading = ref(false)
const formRef = ref(false)

const formModel = {
  user: {
    email: null,
    name: null,
    phone: null,
  },
  manufacturer_email: null,
  comment: null,
  product_list: [],
}
const formData = reactive(cloneDeep(formModel))

async function submit() {
  if (!formRef.value.validate()) return

  isLoading.value = true
  const error = await store.dispatch('catalogs/sendEmail', formData)
  if (error) {
    store.commit('ui/triggerNotifySnackbar', {
      ...DEFAULT_NOTIFY_SNACKBAR,
      message: 'Ошибка при запросе продукта',
      show: true,
      options: {
        ...DEFAULT_NOTIFY_SNACKBAR.options,
        color: 'error',
      },
    })
  } else {
    store.commit('ui/triggerNotifySnackbar', {
      ...DEFAULT_NOTIFY_SNACKBAR,
      message: 'Запрос успешно отправлен',
      show: true,
    })
  }
  isLoading.value = false
  dialog.value = false
}

function show(manufacturerEmail, productName) {
  const { email, name } = store.state.userInfo

  formData.manufacturer_email = manufacturerEmail
  formData.user.email = email
  formData.user.name = name
  formData.product_list.push(productName)

  dialog.value = true
}

watch(
  () => dialog.value,
  (status) => {
    if (!status) Object.assign(formData, cloneDeep(formModel))
  }
)
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="480">
    <v-form ref="formRef" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" color="primary" indeterminate />
        <v-card-title class="pb-4">
          Запрос продукта
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model.trim="formData.user.name"
            label="Имя"
            :rules="[rulesObject.required]"
            outlined
            dense
          />
          <v-text-field
            v-model.trim="formData.user.email"
            label="Эл. почта"
            :rules="[rulesObject.required, rulesObject.email]"
            outlined
            dense
          />
          <v-text-field
            v-model.trim="formData.user.phone"
            label="Телефон"
            :rules="[rulesObject.required]"
            outlined
            dense
          />
          <v-textarea
            v-model.trim="formData.comment"
            label="Комментарий"
            outlined
            dense
          />
        </v-card-text>

        <SaveCloseButtons
          :disabled="isLoading"
          btn-text-save="Отправить"
          @close="dialog = false"
        />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
