<template>
  <div>
    <div v-for="param in options" :key="param.id">
      <v-text-field
        :value="dataBuilder[param.id].value"
        :label="
          param.name +
          (param.symbol ? `(${param.symbol})` : '') +
          (param.is_required ? '*' : '')
        "
        :rules="[(v) => rules(param, v)]"
        :type="param.param_type"
        :hint="param.definition"
        outlined
        dense
        :readonly="checkIfReadonly(param)"
        :disabled="checkIfReadonly(param)"
        @change="(e) => fieldInput(e, param)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { get, each, cloneDeep, set } from 'lodash'
import { checkIfReadonly } from '@/utils/otherHelpers'

export default {
  name: 'FormParamsBuilder',
  props: {
    dataBuilder: {
      type: Object,
      default: null,
    },
    formMode: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    sorting: {},
    options: [],
    checkIfReadonly,
  }),
  computed: mapState('parameters', ['parameters']),
  watch: {
    dataBuilder: {
      deep: true,
      handler(params) {
        let complexes = []
        const object = {}
        const valueArray = []

        each(params, (item, key) => {
          if (item.complex_object) {
            complexes.push(item)
          } else if (item.value || item.value === 0) {
            valueArray.push(`${item.param.name}: ${item.value}`)
            set(object, key, {
              value: item.value,
              param: item.param,
              sorting: get(this.sorting, key, 0),
            })
          }
        })

        const value = valueArray.join('; ')
        if (valueArray.length && complexes.length) {
          each(complexes, (complex) => {
            if (value !== get(complex, 'value')) {
              set(complex, 'complex_object', object)
              set(complex, 'value', value)
            }
          })
        }
      },
    },
  },
  created() {
    const dataBuilder = cloneDeep(this.dataBuilder)

    this.parameters.forEach((param) => {
      const object = {
        param,
        value: get(dataBuilder, `${param.id}.value`, ''),
        options: get(dataBuilder, `${param.id}.options`, {
          instance_parameter: true,
          not_record: true,
          create_param_if_missing: true,
          save_to_family: true,
          overwrite_existing_value: true,
        }),
      }

      this.$set(this.dataBuilder, param.id, object)

      if (
        this.formMode === 'create' ||
        Object.prototype.hasOwnProperty.call(dataBuilder, param.id)
      ) {
        this.options.push(param)
      }

      delete dataBuilder[param.id]
    })
  },
  methods: {
    fieldInput(value, param) {
      if (param.param_type === 'number') {
        value = Number(value)
      } else {
        value = String(value).trim()
      }
      this.$set(this.dataBuilder[param.id], 'value', value)
    },
    rules(param, value) {
      if (value === '' && param.is_required) return 'Обязательно к заполнению'
      if (param.check_regexp && !new RegExp(param.check_regexp).test(value))
        return 'Не пройдена валидация'
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.group {
  position: relative;

  &__icon {
    transform: translateY(-50%);
    position: absolute;
    right: 9px;
    top: calc(50% - 13px);
  }

  &__field::v-deep input {
    padding-right: 30px;
  }
}
</style>
