import {
  BuiltInCategoryEnumeration,
  BuiltInParameterEnumeration,
  RengaBuiltInCategoryEnumeration,
} from '@/constants'

export default {
  revit: {
    Element: [{ code: 'Physical', fullName: 'Физический' }],
    Category: BuiltInCategoryEnumeration.map((code) => ({
      name: code,
      fullName: code,
      code,
    })),
    Parameter: BuiltInParameterEnumeration.map((code) => ({
      name: code,
      fullName: code,
      code,
    })),
  },
  renga: {
    Category: RengaBuiltInCategoryEnumeration.map(({ code, name }) => ({
      name,
      fullName: name,
      code,
    })),
  },
}
