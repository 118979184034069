<template>
  <component :is="wrapperComponent" class="table-form-cell">
    <slot />
    <component
      :is="fieldComponent"
      v-if="editable"
      ref="component"
      v-model="valueModel"
      v-bind="options"
      :disabled="disabled"
      :form-model.sync="formModelAccessors"
      :uuid="uuid"
    />
    <div v-else class="mx-1">
      {{ valueModel }}
    </div>
  </component>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  VTextField,
  VSelect,
  VAutocomplete,
  VSwitch,
  VCombobox,
} from 'vuetify/lib'

export default {
  name: 'TableFormCell',
  components: {
    VTextField,
    VSelect,
    VAutocomplete,
    VSwitch,
    VCombobox,
  },
  props: {
    uuid: {
      type: String,
      default: null,
    },
    wrapperComponent: {
      type: String,
      default: 'td',
    },
    header: {
      type: Object,
      default: null,
    },
    initialValue: {
      type: [Object, Number, String, Boolean],
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    fieldComponent: {
      type: [String, Object],
      default: null,
    },
    fieldOptions: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [Object, Number, String, Boolean],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formModel: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formModelAccessors: {
      get() {
        return this.formModel
      },
      set(value = this.formModel) {
        if (value === this.formModel) return

        this.$emit('update:formModel', value)
      },
    },
    valueModel: {
      get() {
        if (this.modelHasProperty) return this.value

        return this.initialValue
      },
      set(value = this.valueModel) {
        if (value === this.valueModel) return

        this.$emit('input', value)
      },
    },
    options: (vm) => {
      let options = cloneDeep(vm.fieldOptions)
      /**
       * Вычисляемые опции
       * */
      if (typeof options.items === 'function') {
        const [items, storeKey] = options.items(vm.formModel)
        options.items = items
        /**
         * Показываем название свойства в лэйбле, если есть
         * */
        if (typeof options.label === 'function') {
          options.label = options.label(storeKey, vm.valueModel)
        }
      }

      if (typeof options.rules === 'function') {
        options.rules = options.rules()
      }

      return options
    },
    modelHasProperty() {
      return Object.hasOwnProperty.call(
        this.formModel,
        this.getFieldKey(this.header?.value)
      )
    },
  },
  watch: {
    /*
     * Сбрасываем значение селекта в модели, если изменились передаваемые ему опшны
     * */
    'options.items'(newValue, oldValue) {
      if (isEqual(newValue, oldValue)) return

      const itemKey = this.fieldOptions.itemValue ?? 'value'
      const hasItem = newValue?.find(
        (item) => item[itemKey] === this.valueModel
      )
      if (!hasItem) this.$refs.component?.reset?.()

      if (this.modelHasProperty) this.valueModel = null
    },
  },
  methods: {
    getFieldKey(key) {
      return `row_${this.uuid}_cell_${key}`
    },
  },
}
</script>

<style lang="scss" scoped>
.table-form-cell {
  padding: 4px !important;
  height: auto !important;
}
</style>
