import { rulesObject as rules } from '@/mixins/rules'
import {
  FILTER_CATEGORY,
  FILTER_PROPERTY,
  FILTER_CONDITION,
} from '@/components/SimpleFilter/constants'

export default [
  {
    text: 'Свойство является',
    value: 'category',
    sortable: false,
    component: 'v-select',
    options: {
      rules: [rules.required],
      items: FILTER_CATEGORY,
      outlined: true,
      hideDetails: true,
      dense: true,
      itemText: 'name',
      itemValue: 'value',
      returnObject: false,
    },
  },
  {
    text: 'Название свойства',
    value: 'property',
    sortable: false,
    component: 'v-combobox',
    bondWith: ['category'],
    options: {
      rules: function () {
        return this.items?.length ? [rules.required] : []
      },
      /**
       * На основе категории устанавливаем "Свойство"
       * */
      items: function (keys, initialValues) {
        return (model) => {
          const value = model[keys.category] ?? initialValues.category

          const storeKeys = {
            Category: 'builtInCategories',
            Parameter: 'builtInParams',
          }
          const storeKey = storeKeys[value] ?? null
          const revitItems = this.$attrs?.[storeKey]
          const items = revitItems?.length
            ? revitItems
            : FILTER_PROPERTY[this.selectedSapr][value] ?? []
          /**
           * Отдает список свойств и ключ, по которому их можно брать из Ревита
           * */
          return [items, storeKey]
        }
      },
      label: function () {
        return (storeKey, value) => {
          if (!storeKey) return ''

          const itemObject = this.$attrs?.[storeKey]?.find(
            ({ code }) => code === value
          )

          return itemObject?.name ?? ''
        }
      },
      outlined: true,
      hideDetails: true,
      dense: true,
      itemText: 'fullName',
      itemValue: 'code',
      returnObject: false,
    },
  },
  {
    text: 'Условие (применяется ко значению)',
    value: 'condition',
    sortable: false,
    component: 'v-autocomplete',
    bondWith: ['category', 'property'],
    options: {
      rules: function () {
        return this.items?.length ? [rules.required] : []
      },
      /**
       * На основе категории устанавливаем "Условие" и если это объект, то добираем на основе свойства
       * */
      items: function (keys, initialValues) {
        return (model) => {
          const category = model[keys.category] ?? initialValues.category
          let conditions = FILTER_CONDITION[category] ?? []

          if (!Array.isArray(conditions)) {
            const property = model[keys.property] ?? initialValues.property
            const temp = conditions[property] ?? null
            conditions = !temp ? conditions['_'] ?? [] : temp
          }

          return [conditions, null]
        }
      },
      outlined: true,
      hideDetails: true,
      dense: true,
      itemText: 'name',
      itemValue: 'value',
      returnObject: false,
    },
  },
  {
    text: 'Значение',
    value: 'value',
    component: 'v-text-field',
    sortable: false,
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right',
    component: 'span',
  },
]
