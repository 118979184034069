<script setup>
import Vue, { ref, defineExpose, watch, computed, defineEmits } from 'vue'
import { useStore } from '@/store'
import { checkIfCustomReadonly, checkIfShared } from '@/utils/otherHelpers'

import ParamFieldOptions from '@/components/ProjectEir/ParamFieldOptions.vue'

defineExpose({ show })
const emit = defineEmits(['apply'])

const store = useStore()
const dialog = ref(false)
const isLoading = ref(false)
const allParams = ref([])
const fensysParams = ref([])
const params = ref([])
const productName = ref(null)
const productUrl = ref(null)
const formData = ref({})
const selectedAction = ref({})
const options = ref({})
const eirElId = ref(null)
const saprElList = ref(null)
const addedParams = ref([])
const requirementParameters = computed(() => [
  ...(store.state.parameters?.parameters ?? []).map(prepareParam),
  ...addedParams.value,
])
const toChooseParams = computed(() =>
  allParams.value.filter((param) => !params.value.includes(param))
)
const buttonDisabled = computed(() => !saprElList.value?.length)

watch(
  () => dialog.value,
  (status) => {
    if (!status) {
      formData.value = {}
      selectedAction.value = {}
    }
  }
)

function initParam(param) {
  Vue.set(formData.value, param.id, {
    productParam: param,
    value: param?.value ?? null,
    param: null,
  })
  Vue.set(selectedAction.value, param.id, null)
  Vue.set(options.value, param.id, {
    overwriteExistingValue: true,
    writeDown: true,
  })
}
function getParamCreatingStatus(param) {
  return store.getters['revit/getParamCreatingStatus'](
    eirElId.value,
    param.name
  )
}
function checkIfParamIsNotPresent(param) {
  return store.getters['revit/checkIfParamIsNotPresent'](
    eirElId.value,
    param.name
  )
}
function checkIfParamIsReadonly(param) {
  return store.getters['revit/checkIfParamIsReadonly'](
    eirElId.value,
    param.name
  )
}
function prepareParam(param) {
  const readOnly = checkIfCustomReadonly(param) || checkIfParamIsReadonly(param)
  return {
    id: param.id,
    name: param.name,
    value: null,
    saprFields: param.sapr_parameters,
    sections: param.sections.map(({ id }) => id),
    overwriteExistingValue: true,
    writeDown: true,
    readOnly,
    isShared: checkIfShared(param),
    notPresentOnAnySelectedEl: checkIfParamIsNotPresent(param),
  }
}
function checkIsPossibleCreate(param) {
  return store.getters['revit/checkParameterExistence']('name', param.name)
}

async function send() {
  isLoading.value = true

  const parameters = []
  const paramsToCreate = []

  for (const key in formData.value) {
    const object = formData.value[key]
    if (!object.value) continue

    if (selectedAction.value[key] === 'new') {
      paramsToCreate.push({
        name: object.productParam.name,
        saprFields: {
          revitDataType: 'Text',
          revitGuid: '---------',
          revitIsReadonly: false,
          revitIsShared: false,
          revitIsSystem: '',
          revitParameterGroup: 'PG_GENERAL',
          revitType: 'Экземпляр',
        },
      })
    }

    const parameter = {
      ...object.productParam,
      ...object.param,
      value: object.value,
      saprFields: {
        revitDataType: 'Text',
        revitGuid: '---------',
        revitIsReadonly: false,
        revitIsShared: false,
        revitIsSystem: '',
        revitParameterGroup: 'PG_GENERAL',
        revitType: 'Экземпляр',
      },
    }

    if (
      object.value !== object.productParam.value ||
      selectedAction.value[key] === 'select'
    ) {
      if (selectedAction.value[key] === 'select') {
        parameter.sections.push(object.productParam)
        if (!checkIsPossibleCreate(object.productParam)) {
          const createParam = {
            name: object.productParam.name,
            value: object.value,
            saprFields: {
              revitDataType: 'Text',
              revitGuid: '---------',
              revitIsReadonly: false,
              revitIsShared: false,
              revitIsSystem: '',
              revitParameterGroup: 'PG_GENERAL',
              revitType: 'Экземпляр',
            },
            ...options.value[key],
          }

          await store.dispatch('revit/createParamRevit', {
            parameter: {
              ...createParam,
            },
            eirElementId: eirElId.value,
          })

          parameters.push(createParam)
        }
        if (!checkIsPossibleCreate(object.param)) {
          await store.dispatch('revit/createParamRevit', {
            parameter,
            eirElementId: eirElId.value,
          })
        }
        object.param = parameter
      }

      parameters.push({
        id: object.param?.id ?? object.productParam?.id,
        name: object.param?.name ?? object.productParam?.name,
        value: object.value,
        ...object.productParam,
        ...parameter,
        ...options.value[key],
      })
    }
  }

  for (const parameter of paramsToCreate) {
    await store.dispatch('revit/createParamRevit', {
      parameter,
      eirElementId: eirElId.value,
    })
  }

  const elementsIds = saprElList.value.map(({ id }) => id)
  await store.dispatch('revit/applyParamsRevit', {
    parameters,
    elementsIds,
    eirElementId: eirElId.value,
  })

  store.commit('revit/resetCreateStatus', eirElId.value)
  emit('apply')

  isLoading.value = false
  dialog.value = false
}
function show(product, saprElementList, eirElementId) {
  fensysParams.value = []
  saprElList.value = saprElementList
  eirElId.value = eirElementId
  productName.value = product.name
  productUrl.value = product.url

  if (Array.isArray(product.parameters)) {
    allParams.value = product.parameters
    allParams.value.forEach(initParam)
    params.value = allParams.value.slice(0, 5)
  } else {
    for (const category in product.parameters) {
      const items = product.parameters[category]
      fensysParams.value.push({ name: category, value: items, show: false })
    }
    fensysParams.value.forEach((param) => {
      param.value.forEach(initParam)
    })
  }

  dialog.value = true
}
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="875">
    <v-card v-if="dialog">
      <v-progress-linear v-if="isLoading" color="primary" indeterminate />
      <v-card-title class="mb-2">
        <div>
          Список параметров
          <div class="my-title-product">
            {{ productName }}
          </div>
        </div>
        <v-spacer />
        <v-icon title="Закрыть" @click="dialog = false"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <template v-if="fensysParams.length">
          <div class="list-main">
            <div v-for="item in fensysParams" :key="item.nmae">
              <span class="list-item" @click="item.show = !item.show"
                >{{ item.name }}
                <v-icon color="primary" class="ml-2">
                  {{
                    item.show ? 'mdi-chevron-up-box' : 'mdi-chevron-down-box'
                  }}
                </v-icon></span
              >
              <v-simple-table v-if="item.show">
                <thead>
                  <tr>
                    <th>Наименование</th>
                    <th>Значение/Варианты значения</th>
                    <th style="width: 30%">Исходные параметры</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="param in item.value" :key="param.id">
                    <td>{{ param.name }}</td>
                    <td>
                      <ParamFieldOptions
                        v-if="
                          param.name && formData[param.id].productParam?.name
                        "
                        v-model="formData[param.id].value"
                        :param="formData[param.id].productParam"
                        :form-data="formData"
                      />
                    </td>
                    <td>
                      <!-- <pre>{{ formData[param.id] }}</pre> -->
                      <template v-if="param.name">
                        <div v-if="!selectedAction[param.id]">
                          <v-icon
                            class="mr-2"
                            color="primary"
                            size="20"
                            title="Сопоставить с существующим"
                            @click="selectedAction[param.id] = 'select'"
                          >
                            mdi-chevron-right-box
                          </v-icon>
                          <v-icon
                            v-if="!checkIsPossibleCreate(param)"
                            color="grey"
                            size="20"
                            title="Создать новый"
                            @click="selectedAction[param.id] = 'new'"
                          >
                            mdi-plus-box
                          </v-icon>
                        </div>
                        <v-autocomplete
                          v-else-if="selectedAction[param.id] === 'select'"
                          v-model="formData[param.id].param"
                          :items="requirementParameters"
                          item-value="name"
                          item-text="name"
                          return-object
                          dense
                          hide-details
                          outlined
                          append-outer-icon="mdi-close"
                          @click:append-outer="
                            selectedAction[param.id] = null
                            formData[param.id].param = null
                          "
                          @change="(v) => (formData[param.id].param = v)"
                        />
                        <div
                          v-else-if="selectedAction[param.id] === 'new'"
                          class="d-flex align-center"
                          style="
                            line-height: 1.2;
                            white-space: nowrap;
                            font-size: 12px;
                          "
                        >
                          Создать новый <br />параметр
                          <v-icon
                            v-if="!addedParams.includes(param)"
                            class="ml-1"
                            size="20"
                            @click="selectedAction[param.id] = null"
                          >
                            mdi-close
                          </v-icon>
                        </div>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </template>
        <template v-else>
          <v-simple-table>
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Значение/Варианты значения</th>
                <th style="width: 30%">Исходные параметры</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="param in params" :key="param.id">
                <td>{{ param.name }}</td>
                <td>
                  <ParamFieldOptions
                    v-if="param.name && formData[param.id].productParam?.name"
                    v-model="formData[param.id].value"
                    :param="formData[param.id].productParam"
                    :form-data="formData"
                  />
                </td>
                <td>
                  <!-- <pre>{{ formData[param.id] }}</pre> -->
                  <template v-if="param.name">
                    <div v-if="!selectedAction[param.id]">
                      <v-icon
                        class="mr-2"
                        color="primary"
                        size="20"
                        title="Сопоставить с существующим"
                        @click="selectedAction[param.id] = 'select'"
                      >
                        mdi-chevron-right-box
                      </v-icon>
                      <v-icon
                        v-if="!checkIsPossibleCreate(param)"
                        color="grey"
                        size="20"
                        title="Создать новый"
                        @click="selectedAction[param.id] = 'new'"
                      >
                        mdi-plus-box
                      </v-icon>
                    </div>
                    <v-autocomplete
                      v-else-if="selectedAction[param.id] === 'select'"
                      v-model="formData[param.id].param"
                      :items="requirementParameters"
                      item-value="name"
                      item-text="name"
                      return-object
                      dense
                      hide-details
                      outlined
                      append-outer-icon="mdi-close"
                      @click:append-outer="
                        selectedAction[param.id] = null
                        formData[param.id].param = null
                      "
                      @change="(v) => (formData[param.id].param = v)"
                    />
                    <div
                      v-else-if="selectedAction[param.id] === 'new'"
                      class="d-flex align-center"
                      style="
                        line-height: 1.2;
                        white-space: nowrap;
                        font-size: 12px;
                      "
                    >
                      Создать новый <br />параметр
                      <v-icon
                        v-if="!addedParams.includes(param)"
                        class="ml-1"
                        size="20"
                        @click="selectedAction[param.id] = null"
                      >
                        mdi-close
                      </v-icon>
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </template>
        <v-menu v-if="toChooseParams?.length">
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mt-2"
              outlined
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-plus </v-icon>
              Добавить параметр
            </v-btn>
          </template>
          <v-list max-height="400" class="overflow-y-auto" dense>
            <v-list-item
              v-for="param in toChooseParams"
              :key="param.id"
              @click="params.push(param)"
            >
              <v-list-item-title>{{ param.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-text>
      <v-card-actions class="pb-6 pr-6">
        <v-spacer />
        <v-btn elevation="0" class="btn" @click="dialog = false">
          Отменить
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="btn"
          :disabled="buttonDisabled"
          @click="send"
        >
          Записать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.my-title-product {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  max-width: 600px;
  word-break: break-word;
}

:deep(.v-dialog) {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bababa;
    border-radius: 4px;
    border: 0 none #ffffff;
  }
}

.option-slider {
  padding-top: 12px;

  &::v-deep {
    .v-slider__thumb {
      left: -3px;
    }

    .v-slider--horizontal {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
}

.btn {
  text-transform: uppercase;
  font-weight: 400;
}

.list-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list-item {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.1rem;
  cursor: pointer;
}
</style>
