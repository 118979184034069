<template>
  <v-autocomplete
    v-model="selected"
    clearable
    hide-selected
    multiple
    small-chips
    deletable-chips
    outlined
    chips
    dense
    prepend-inner-icon="mdi-filter-multiple-outline"
    label="Выберите разделы"
    :item-text="getText"
    item-value="id"
    :items="sections"
    :hide-details="!persistentHint"
    :hint="hint"
    :persistent-hint="persistentHint"
  >
    <template #selection="{ item }">
      <v-chip small close @click:close="clickClose(item)">
        {{ item.name }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'SelectSections',
  props: {
    optionKey: {
      type: String,
      default: null,
    },
    optionKeyDefault: {
      type: String,
      default: null,
    },
    dataDefault: {
      type: [String, Number],
      default: '',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    delay: {
      type: Number,
      default: 1000,
    },
    persistentState: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: [],
    setTimeout: null,
  }),
  computed: mapState('sections', ['sections', 'selectState']),
  watch: {
    selected(array = []) {
      const options = {}

      if (array?.length) {
        options[this.optionKey] = array.join(',')
      } else {
        options[this.optionKeyDefault] = this.dataDefault
      }
      /**
       * Даём немного времени, чтобы успеть выбрать и не делать множество запросов
       * */
      clearTimeout(this.setTimeout)
      this.setTimeout = setTimeout(() => {
        this.$emit('input', options, array)

        if (this.persistentState) this.setSectionSelectState(array)
      }, this.delay)
    },
  },
  created() {
    if (this.persistentState) this.selected = this.selectState ?? []
  },
  methods: {
    ...mapMutations('sections', ['setSectionSelectState']),
    getText({ name, description }) {
      description = description ? ` — ${description}` : ''

      return `${name}${description}`
    },
    clickClose(item) {
      const index = this.selected.findIndex((id) => id === item.id)
      this.selected.splice(index, 1)
    },
  },
}
</script>
