<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="600"
      persistent
      @keydown.esc="dialog = false"
    >
      <v-form ref="form" v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-progress-linear v-if="isLoading" color="primary" indeterminate />

          <div class="pa-4 pt-1">
            <v-card-title class="pb-4">
              {{
                formMode === 'create'
                  ? 'Добавление экземпляра'
                  : 'Редактирование экземпляра'
              }}
              <v-spacer />
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model.trim="fields.name"
                label="Наименование*"
                :rules="[rules.required]"
                outlined
                dense
              />

              <v-card outlined>
                <v-card-title style="font-size: 1rem">Параметры</v-card-title>
                <v-card-text>
                  <FormParamsBuilder
                    v-if="dialog"
                    :data-builder="dataBuilder"
                    :form-mode="formMode"
                  />
                </v-card-text>
              </v-card>
              <div class="mt-11">*&nbsp;&mdash; обязательно</div>
            </v-card-text>

            <SaveCloseButtons :disabled="isLoading" @close="dialog = false" />
          </div>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import get from 'lodash/get'
import rules from '@/mixins/rules'
import Instance from '@/models/Instance'
import SaveCloseButtons from '@/components/SaveCloseButtons'
import FormParamsBuilder from './FormParamsBuilder'

export default {
  name: 'ModalInstances',
  components: {
    SaveCloseButtons,
    FormParamsBuilder,
  },
  mixins: [rules],
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: false,
    formMode: 'create',
    dataBuilder: {},
    fields: Instance.getModel(),
  }),
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
        this.fields = Instance.getModel()
        this.dataBuilder = {}
      }
    },
  },
  methods: {
    ...mapActions('eirInstances', [
      'fetchEirInstances',
      'createEirInstance',
      'updateEirInstance',
    ]),
    show(data, formMode = 'create') {
      this.formMode = formMode
      if (formMode === 'create') {
        this.fields.eir_element = this.$route.params.eirId
        this.fields.data.params = this.dataBuilder
      } else {
        this.$set(this, 'fields', { ...data })
        this.dataBuilder = { ...get(this.fields, 'data.params', {}) }
      }
      this.dialog = true
    },
    async submit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.isLoading = true
        let data = {}

        if (this.formMode === 'create') {
          const resp = await this.createEirInstance(this.fields)
          data = resp.data
          await this.$logActionEvent('Добавлен экземпляр требования')
        } else {
          this.fields.data.params = { ...this.dataBuilder }
          await this.updateEirInstance(this.fields)
          data = this.fields
          await this.$logActionEvent('Изменён экземпляр требования')
        }

        this.$emit('open-view', data)

        await this.fetchEirInstances({
          filters: { eir_element: this.$route.params.eirId },
        })

        this.isLoading = false
        this.dialog = false
      }

      return this.valid
    },
  },
}
</script>
