<template>
  <div>
    <v-data-table
      class="custom-table-2"
      loading-text="Загрузка... Пожалуйста, подождите"
      :headers="headers"
      :items="parameters"
      :loading="isLoading"
      :search="search"
      :items-per-page="20"
      :hide-default-footer="totalParameters <= 20"
      :server-items-length="totalParameters"
      :options.sync="tableOptions"
      :mobile-breakpoint="0"
      :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
      disable-sort
    >
      <template v-slot:top>
        <div class="d-flex pt-1 mb-4">
          <v-text-field
            v-model="search"
            label="Поиск по наименованию"
            style="max-width: 500px"
            hide-details
            outlined
            dense
          />
          <v-btn
            v-if="sortArray.length"
            class="ml-3"
            large
            color="primary"
            elevation="10"
            @click="saveSort"
          >
            Сохранить сортировку
          </v-btn>
        </div>
      </template>

      <template #body="props">
        <draggable
          tag="tbody"
          :list="props.items"
          :move="onMoveCallback"
          :disabled="designer"
          @end="onDropCallback"
        >
          <DataTableRowHandler
            v-for="(item, index) in props.items"
            :key="index"
            :item="item"
            :headers="headers"
          >
            <template v-if="!designer" #item.sort>
              <v-icon class="cursor-sort" x-small>mdi-arrow-up-down</v-icon>
            </template>

            <template
              #item.name_symbol="{
                item: { name, symbol, is_required: isRequired },
              }"
            >
              <div :title="name" class="mt-1">
                {{ name | truncate(50) }}
                <sup
                  v-if="isRequired"
                  title="Обязателен"
                  class="primary--text body-2"
                >
                  *
                </sup>
              </div>
              <div v-if="symbol" :title="symbol" class="mb-1">
                {{ symbol | truncate(50) }}
              </div>
            </template>

            <template #item.definition="{ item: { definition } }">
              <div :title="definition">{{ definition | truncate(90) }}</div>
            </template>

            <template
              #item.eirs_count="{
                item: { eir_elements: eirElements, eir_count: eirCount },
              }"
            >
              <DropdownMenu
                :items="eirElements"
                :items-count="eirCount"
                :link-path="`/eir-sets/${setId}/eirs`"
                :append="false"
              />
            </template>

            <template #item.unit_type="{ item: { unit_type: unitType } }">
              {{ getUnitTypeName(unitType) }}
            </template>

            <template
              #item.REVIT_IS_SHARED="{
                item: { sapr_parameters: saprParameters },
              }"
            >
              {{ getSaprParameterValue(saprParameters, 'REVIT_IS_SHARED') }}
            </template>

            <template
              #item.REVIT_IS_SYSTEM="{
                item: { sapr_parameters: saprParameters },
              }"
            >
              {{ getSaprParameterValue(saprParameters, 'REVIT_IS_SYSTEM') }}
            </template>

            <template #item.updated_at="{ item: { updated_at: updatedAt } }">
              {{ updatedAt | formatDate }}
            </template>

            <template v-if="!designer" #item.actions="{ item: prop }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    small
                    v-on="on"
                    @click="$refs.modalParameter.show(prop, 'update')"
                  >
                    mdi-pencil-outline
                  </v-icon>
                </template>
                <span>Редактировать</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    :color="prop.sapr_parameters?.length ? 'primary' : 'error'"
                    small
                    v-on="on"
                    @click="
                      $refs.modalParameterSapr.show({
                        data: prop.sapr_parameters,
                        paramId: prop.id,
                      })
                    "
                  >
                    mdi-alpha-r-box-outline
                  </v-icon>
                </template>
                <span>Заполнить поля САПРа</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    small
                    v-on="on"
                    @click="removeParameter(prop)"
                  >
                    mdi-delete-outline
                  </v-icon>
                </template>
                <span>Удалить</span>
              </v-tooltip>
            </template>
          </DataTableRowHandler>
        </draggable>
      </template>
    </v-data-table>

    <ModalParameter ref="modalParameter" @updated="$emit('updated')" />
    <ModalParameterSapr
      ref="modalParameterSapr"
      @update-parameters-list="update"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import each from 'lodash/each'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import draggable from 'vuedraggable'

import rules from '@/mixins/rules'
import { TAB_PARAMETERS_TABLE_HEADERS } from '@/views/Eir/constants'
import { DisplayUnitType } from '@/constants'

import ModalParameter from '@/components/ModalParameter'
import ModalParameterSapr from '@/components/ModalParameterSapr'
import DataTableRowHandler from '@/components/DataTableRowHandler'
import DropdownMenu from '@/components/DropdownMenu'

export default {
  name: 'TabParameters',
  components: {
    ModalParameter,
    ModalParameterSapr,
    draggable,
    DataTableRowHandler,
    DropdownMenu,
  },
  mixins: [rules],
  props: {
    designer: {
      type: Boolean,
      default: false,
    },
    setId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data: () => ({
    isLoading: false,
    search: '',
    TAB_PARAMETERS_TABLE_HEADERS,
    sortArray: [],
    tableOptions: {},
  }),
  computed: {
    ...mapState('parameters', [
      'parameters',
      'totalParameters',
      'paramFilters',
      'saprFields',
    ]),
    headers: (vm) => {
      let result = cloneDeep(TAB_PARAMETERS_TABLE_HEADERS)

      if (!vm.designer) {
        result.unshift({
          text: '',
          value: 'sort',
          width: '23px',
        })

        result.push({
          text: '',
          value: 'actions',
          width: '85px',
        })
      }

      return result
    },
    IdByKeys: (vm) =>
      vm.saprFields.reduce((acc, item) => {
        acc[item.name] = item.id

        return acc
      }, {}),
  },
  watch: {
    tableOptions() {
      this.debounceFetch()
    },
    search() {
      if (this.tableOptions.page !== 1) {
        this.tableOptions.page = 1
      } else {
        this.debounceFetch()
      }
    },
  },
  beforeDestroy() {
    this.resetParametersState()
  },
  created() {
    this.fetchSaprFields()
  },
  methods: {
    ...mapMutations('parameters', ['resetParametersState']),
    ...mapActions('parameters', [
      'fetchParams',
      'deleteParameter',
      'changeParameters',
      'fetchSaprFields',
    ]),
    debounceFetch: debounce(function () {
      this.fetchParamsWrapper()
    }, 400),
    async fetchParamsWrapper() {
      this.isLoading = true

      const params = {}
      const setId = this.setId ?? this.$route.params.setId
      const eirId = this.$route.params.eirId

      if (eirId) params.eir = this.$route.params.eirId
      else if (!isNaN(+setId)) params.eir_set = this.$route.params.setId

      await this.fetchParams({
        options: this.tableOptions,
        filters: {
          ...this.paramFilters,
          search: this.search,
          ...params,
        },
      })
      this.isLoading = false
    },
    async removeParameter(item) {
      if (confirm('Удалить параметр?')) {
        this.isLoading = true
        await this.deleteParameter(item.id)
        await this.$logActionEvent('Параметр удалён')
        await this.fetchParamsWrapper()
        this.isLoading = false
        this.$emit('updated')
      }
    },
    onMoveCallback(evt) {
      this.$nextTick(() => {
        each(evt.to.children, (el, index) => {
          el.__vue__.item.sorting_priority = evt.to.children.length - index
        })
      })
    },
    onDropCallback() {
      this.sortArray = this.parameters.map(({ id, sorting_priority }) => ({
        id,
        sorting_priority,
      }))
    },
    async saveSort() {
      this.isLoading = true
      await this.changeParameters(this.sortArray)
      await this.$logActionEvent('Изменена сортировка параметров')
      this.sortArray = []
      this.isLoading = false
      this.$emit('updated')
    },
    getUnitTypeName(unitTypeKey) {
      return DisplayUnitType[unitTypeKey] ?? ''
    },
    getSaprParameterValue(saprParameters, key) {
      const id = this.IdByKeys[key]
      const value = saprParameters?.find((param) => param.id === id)?.value

      if (typeof value === 'boolean') return value ? 'Да' : 'Нет'
      else return value
    },
    update() {
      this.debounceFetch()
      this.$emit('updated')
    },
  },
}
</script>

<style scoped lang="scss">
.cursor-sort {
  cursor: all-scroll;
}

.custom-table-2::v-deep {
  td:first-child,
  th:first-child {
    padding: 0 0 0 10px;
    border-right: 0 !important;
  }

  td:nth-child(2),
  th:nth-child(2) {
    border-left: 0 !important;
    padding-left: 9px;
  }

  .sortable-chosen {
    background-color: rgba(0, 175, 161, 0.1) !important;
  }
}
</style>
