<template>
  <v-menu
    v-if="items?.length > 1"
    v-model="showItems"
    open-on-hover
    transition="slide-y-transition"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        color="primary"
        v-bind="attrs"
        style="min-width: 0"
        icon
        v-on="on"
      >
        {{ itemsCount ?? items?.length }}
        <v-icon small>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-card max-height="400">
      <v-list dense>
        <template v-for="({ id: itemId, name }, i) in items">
          <v-list-item :key="`item-${itemId}`" :title="name">
            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  :append="append"
                  :target="targetBlank ? '_blank' : '_self'"
                  :to="linkPath ? `${linkPath}/${itemId}` : false"
                >
                  {{ name | truncate(70) }}
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="i !== items?.length - 1"
            :key="`divider-${itemId}-${i}`"
          />
        </template>
      </v-list>
    </v-card>
  </v-menu>
  <router-link
    v-else-if="items?.length"
    :append="append"
    :to="linkPath ? `${linkPath}/${items[0].id}` : false"
    :title="items[0].name"
    :target="targetBlank ? '_blank' : '_self'"
    @click.native.stop
  >
    {{ items[0].name | truncate(70) }}
  </router-link>
  <span v-else>&mdash;</span>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    linkPath: {
      type: String,
      default: null,
    },
    itemsCount: {
      type: Number,
      default: null,
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
    append: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    showItems: false,
  }),
}
</script>
