<script setup>
import {
  onMounted,
  onBeforeUnmount,
  defineEmits,
  defineProps,
  ref,
  nextTick,
} from 'vue'

const emit = defineEmits(['onScrolledToEnd'])
const props = defineProps({
  items: { type: Array, default: () => [] },
  height: { type: Number, default: 0 },
  itemHeight: { type: Number, default: 0 },
})

/**
 * Событие при прокрутке до конца
 * */
const requestHasBeenSent = ref(null)
const currentLastEl = ref(null)
const vScrollRef = ref(null)
const vScrollObserver = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach(async (entry) => {
      await nextTick()
      if (entry.isIntersecting && !requestHasBeenSent.value) {
        emit('onScrolledToEnd')
        requestHasBeenSent.value = true
      } else if (!entry.isIntersecting) {
        requestHasBeenSent.value = false
      }
      observer.unobserve(entry.target)
      observer.observe(getPenultimateElement())
    })
  },
  {
    threshold: 1,
  }
)

onMounted(async () => {
  await nextTick()
  vScrollObserver.observe(getPenultimateElement())
})
onBeforeUnmount(() => vScrollObserver.unobserve(currentLastEl.value))

function getPenultimateElement() {
  const children = vScrollRef.value?.$children
  if (!children) return null
  const last = children[children.length - 3]
  currentLastEl.value = last.$el
  return last.$el
}
</script>

<template>
  <v-virtual-scroll
    ref="vScrollRef"
    v-bind="{ ...$attrs, ...props }"
    :bench="10"
  >
    <template #default="props">
      <slot v-bind="props" />
    </template>
  </v-virtual-scroll>
</template>
