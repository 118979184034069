<template>
  <v-dialog
    v-model="dialog"
    width="476"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" color="primary" indeterminate />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            Заполнение полей САПРа
            <v-spacer />
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-expand-transition>
              <v-alert
                v-show="canSave"
                dense
                text
                type="warning"
                dismissible
                class="mb-6"
              >
                Изменения не сохранены
              </v-alert>
            </v-expand-transition>

            <v-select
              v-model="currentSapr"
              label="САПР*"
              :rules="[rules.required]"
              :items="saprSystems"
              item-value="value"
              item-text="text"
              outlined
              dense
            />
            <v-select
              v-if="currentSapr && currentSapr === 'revit'"
              v-model="variant"
              label="Вариант параметра*"
              :rules="[rules.required]"
              :items="variantList"
              item-value="value"
              item-text="text"
              outlined
              dense
              @input="resetFields"
            />
            <v-text-field
              v-if="variant === 0 || currentSapr === 'renga'"
              :value="getValue('GUID')"
              :label="`${getLabel('GUID')}*`"
              :rules="[rules.required, rules.guid]"
              outlined
              dense
              @input="(v) => setValue('GUID', v)"
            />
            <v-autocomplete
              v-if="variant === 1 && currentSapr === 'revit'"
              v-model="fields.REVIT_IS_SYSTEM"
              :label="`${labels.REVIT_IS_SYSTEM}*`"
              :rules="[rules.required]"
              :items="BuiltInParameterEnumeration"
              outlined
              dense
            />
            <v-select
              v-if="variant === 0 && currentSapr === 'revit'"
              :value="getValue('TYPE')"
              :label="`${getLabel('TYPE')}*`"
              :items="['Тип', 'Экземпляр']"
              outlined
              clearable
              dense
              @input="(v) => setValue('TYPE', v)"
            />
            <v-autocomplete
              v-if="variant === 0 || variant === 2 || currentSapr === 'renga'"
              :value="getValue('DATA_TYPE')"
              :label="`${getLabel('DATA_TYPE')}*`"
              :items="parameterTypeItems"
              item-value="key"
              item-text="text"
              outlined
              dense
              clearable
              @input="(v) => setValue('DATA_TYPE', v)"
            />
            <v-autocomplete
              v-if="variant === 0 && currentSapr === 'revit'"
              v-model="fields.REVIT_PARAMETER_GROUP"
              :items="parameterGroupItems"
              :label="labels.REVIT_PARAMETER_GROUP"
              item-value="key"
              item-text="text"
              hide-details
              outlined
              dense
              clearable
            />
            <v-switch
              v-if="currentSapr === 'revit'"
              v-model="fields.REVIT_IS_READONLY"
              :label="labels.REVIT_IS_READONLY"
              hint="Нельзя будет заполнить этот параметр в экземпляре"
              :persistent-hint="fields.REVIT_IS_READONLY"
            />
            <div class="mt-6">*&nbsp;&mdash; обязательно</div>
          </v-card-text>

          <SaveCloseButtons
            :disabled="isLoading"
            :disabled-btn-save="!canSave"
            @close="dialog = false"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { mapActions, mapGetters, mapState } from 'vuex'

import rules from '@/mixins/rules'
import {
  BuiltInParameterEnumeration,
  ParameterType,
  RengaParameterType,
  ParameterGroup,
  SAPR_FIELDS_LABELS,
  SAPR_SYSTEMS,
} from '@/constants'

import ParameterSapr from '@/models/ParameterSapr'
import SaveCloseButtons from '@/components/SaveCloseButtons'

export default {
  name: 'ModalParameterSapr',
  components: { SaveCloseButtons },
  mixins: [rules],
  data: () => ({
    currentSapr: 'revit',
    valid: true,
    dialog: false,
    isLoading: false,
    fields: ParameterSapr.getModel(),
    snapshot: ParameterSapr.getModel(),
    variant: 0,
    variantList: [
      { value: 0, text: 'Общий' },
      { value: 1, text: 'Системный' },
      { value: 2, text: 'Параметр семейства' },
    ],
    BuiltInParameterEnumeration,
    saprSystems: Object.entries(SAPR_SYSTEMS).map(([key, value]) => ({
      value: key,
      text: value.name,
    })),
    fieldsAliases: {
      GUID: { revit: 'REVIT_GUID', renga: 'rengaGUID' },
      DATA_TYPE: { revit: 'REVIT_TYPE', renga: 'rengaParameterType' },
      TYPE: { revit: 'REVIT_DATA_TYPE', renga: 'rengaParameterValueType' },
    },
    parameterGroupItems: Object.entries(ParameterGroup)
      .map(([key, text]) => ({
        key,
        text,
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    labels: SAPR_FIELDS_LABELS,
  }),
  computed: {
    ...mapState('parameters', ['saprFields']),
    ...mapGetters('parameters', ['maxSortingPriority']),
    parameterTypeItems: (vm) =>
      Object.entries(
        vm.currentSapr === 'renga' ? RengaParameterType : ParameterType
      )
        .map(([key, text]) => ({
          key,
          text,
        }))
        .sort((a, b) => (a.text > b.text ? 1 : -1)),
    keysById: (vm) =>
      vm.saprFields.reduce((acc, item) => {
        acc[item.id] = item.name

        return acc
      }, {}),
    IdByKeys: (vm) =>
      vm.saprFields.reduce((acc, item) => {
        acc[item.name] = item.id

        return acc
      }, {}),
    canSave: (vm) => !isEqual(vm.fields, vm.snapshot),
  },
  watch: {
    dialog(val) {
      if (!val) this.reset()
    },
  },
  methods: {
    ...mapActions('parameters', [
      'fetchParams',
      'createParameter',
      'updateParameter',
      'editSaprFields',
    ]),
    getLabel(key) {
      return this.labels[this.fieldsAliases[key][this.currentSapr]]
    },
    getValue(key) {
      return this.fields[this.fieldsAliases[key][this.currentSapr]]
    },
    setValue(key, value) {
      this.fields[this.fieldsAliases[key][this.currentSapr]] = value
    },
    show({ data = [], paramId }) {
      this.paramId = paramId

      const currentFields = data.reduce((acc, item) => {
        acc[this.keysById[item.id]] = item.value

        return acc
      }, {})

      if (data.length && !currentFields.REVIT_IS_SHARED)
        this.variant = currentFields.REVIT_IS_SYSTEM ? 1 : 2

      this.fields = ParameterSapr.getModel(currentFields)
      this.snapshot = cloneDeep(this.fields)

      if (this.fields.rengaGUID) this.currentSapr = 'renga'
      else this.currentSapr = 'revit'

      /*
       * Подставляем шаблон, если общий параметр
       * */
      if (this.variant === 0) this.setTemplate()

      this.dialog = true
    },
    async submit() {
      this.$refs.form.validate()
      if (!this.valid) return

      this.isLoading = true

      await this.editSaprFields({
        paramId: this.paramId,
        fields: this.prepareFields(this.fields),
      })
      this.$emit('update-parameters-list')

      this.isLoading = false
      this.dialog = false
    },
    prepareFields(fields) {
      const keys = [
        [
          'REVIT_GUID',
          'REVIT_TYPE',
          'REVIT_DATA_TYPE',
          'REVIT_PARAMETER_GROUP',
          'REVIT_IS_SHARED',
          'REVIT_IS_SYSTEM',
          'REVIT_IS_READONLY',
        ],
        ['REVIT_IS_SHARED', 'REVIT_IS_SYSTEM', 'REVIT_IS_READONLY'],
        [
          'REVIT_DATA_TYPE',
          'REVIT_IS_SHARED',
          'REVIT_IS_SYSTEM',
          'REVIT_IS_READONLY',
        ],
      ]
      const rengaKeys = [
        'rengaGUID',
        'rengaParameterType',
        'rengaParameterValueType',
      ]
      const currentKeys =
        this.currentSapr === 'renga' ? rengaKeys : keys[this.variant]
      let payload = []

      Object.entries(fields).forEach(([key, value]) => {
        if (!currentKeys.includes(key)) return

        if (value === null) value = '' // иначе бэк ругается

        payload.push({ id: this.IdByKeys[key], value })
      })

      return payload
    },
    setTemplate() {
      const template = {
        REVIT_DATA_TYPE: 'Number',
        REVIT_PARAMETER_GROUP: 'PG_GENERAL',
        REVIT_TYPE: 'Экземпляр',
      }
      Object.entries(template).forEach(([key, value]) => {
        if (!this.fields[key]) this.fields[key] = value
      })
    },
    resetFields(variant) {
      /*
       * Сбрасываем значения полей каждый раз при смене варианта
       * */
      this.fields = ParameterSapr.getModel()
      this.fields.REVIT_IS_SHARED = variant === 0
      /*
       * Подставляем шаблон, если общий параметр
       * */
      if (variant === 0) this.setTemplate()
    },
    reset() {
      this.$refs.form.reset()
      setTimeout(() => {
        this.variant = 0
        this.currentSapr = 'revit'
      }, 0)
      this.fields = ParameterSapr.getModel()
      this.snapshot = ParameterSapr.getModel()
    },
  },
}
</script>
