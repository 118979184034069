<template>
  <div>
    <TableForm
      ref="tableForm"
      :selected-sapr="selectedSapr"
      :headers="headers"
      :items="currentFilter.conditions"
      :server-state-items="serverStateFilter?.conditions ?? []"
      :is-loading="isLoading"
      :item-model-class="FilterCondition"
      :access-fn="gettingAccess"
      :hide-warning="designer"
      v-bind="additionalOptions"
      custom-add-button
      need-model
      :can-save="isTemplate"
      :blocks-options="blocksOptions"
      @add-block="addBlock"
      @add-row="addRow"
      @remove-row="removeRow"
      @reset-to-server-state="resetToServerState"
      @submit="submit"
      @apply="apply"
      @update:model="(model) => (filterModel = model)"
    >
      <template #header="{ hide, disabled }">
        <div class="d-flex flex-column">
          <div class="d-flex align-end mb-1">
            <div class="d-flex align-center">
              <v-select
                v-model="selectedSapr"
                class="mr-2"
                outlined
                hide-details
                dense
                label="Выберите САПР"
                :items="saprSystems"
                item-value="value"
                item-text="text"
                style="max-width: 200px"
              />
              <div v-if="captions?.length" class="font-weight-bold">
                Будет искать:
              </div>
            </div>
            <div v-if="!hide && !designer" class="ml-auto">
              <v-btn
                class="ml-2 mb-2"
                color="primary"
                elevation="10"
                large
                :disabled="disabled"
                @click="showModalSelectTemplate"
              >
                Выбрать шаблон
              </v-btn>
              <v-btn
                class="ml-2 mb-2"
                color="primary"
                elevation="10"
                large
                :disabled="disabled"
                :loading="isLoading || templateFilterStatus === 2"
                @click="showModalFilterTemplate"
              >
                Сохранить как шаблон
              </v-btn>
            </div>
          </div>
          <div v-if="captions?.length" class="font-weight-bold">
            <template v-for="(caption, index) in captions">
              <div
                v-if="caption"
                :key="index"
                :class="{ 'mt-4': checkIfNewBlock(index) }"
              >
                <span v-if="index === 0">Все</span>{{ caption }}
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #custom-add-button="{ hide, disabled, addRowFn }">
        <div v-if="!hide" class="d-flex">
          <v-btn
            color="primary"
            large
            elevation="10"
            :height="40"
            :disabled="disabled || !blockUUID"
            @click="addRowFn"
          >
            <v-icon class="pr-3">mdi-table-row-plus-after</v-icon>
            Добавить новое условие
          </v-btn>
          <v-select
            v-model="blockUUID"
            class="ml-2 add-block-select"
            outlined
            hide-details
            :disabled="disabled"
            dense
            label="Номер блока"
            item-text="number"
            item-value="uuid"
            :return-object="false"
            :items="blocks"
          />
        </div>
      </template>
      <template #additional-actions="{ hide, disabled, addBlockFn }">
        <div v-if="!hide" class="d-flex mt-8">
          <v-btn
            color="primary"
            large
            elevation="10"
            :height="40"
            :disabled="disabled"
            class="add-block-button"
            @click="addBlockFn"
          >
            <v-icon class="pr-3">mdi-table-plus</v-icon>
            Добавить блок условий
          </v-btn>
          <v-select
            v-model="blockOperator"
            class="ml-2 add-block-select"
            outlined
            persistent-hint
            hint="По отношению к остальным блокам (логический оператор)"
            :disabled="disabled"
            dense
            label="Назначение блока"
            item-text="name"
            item-value="value"
            :return-object="false"
            :items="FILTER_OPERATOR"
          />
        </div>
      </template>
    </TableForm>

    <ModalFilterTemplate
      v-if="!designer"
      ref="modalFilterTemplate"
      @save="saveAsTemplate"
    />
    <ModalSelectTemplate
      v-if="!designer"
      ref="modalSelectTemplate"
      @select="selectTemplate"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as UUIDv4 } from 'uuid'

import rules from '@/mixins/rules'
import FilterCondition from '@/models/FilterCondition'

import TableForm from '@/components/TableForm/TableForm'
import ModalFilterTemplate from '@/components/ModalFilterTemplate'
import ModalSelectTemplate from '@/components/ModalSelectTemplate'

import { filterTableHeaders } from '@/components/SimpleFilter/computables'
import { SAPR_SYSTEMS } from '@/constants'
import {
  FILTER_OPERATOR,
  FILTER_PROPERTY,
} from '@/components/SimpleFilter/constants'

export default {
  name: 'SimpleFilter',
  components: { TableForm, ModalFilterTemplate, ModalSelectTemplate },
  mixins: [rules],
  props: {
    designer: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSandbox: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    FilterCondition,
    headers: filterTableHeaders,
    dictionariesAreLoading: false,
    FILTER_OPERATOR,
    blockOperator: 'Or',
    blockUUID: null,
    filterModel: [],
    isTemplate: false,
    selectedSapr: 'revit',
    saprSystems: Object.entries(SAPR_SYSTEMS).map(([key, value]) => ({
      value: key,
      text: value.name,
    })),
  }),
  computed: {
    ...mapState('filters', [
      'currentFilter',
      'currentFilterStatus',
      'serverStateFilter',
      'templateFilterStatus',
    ]),
    ...mapState('eirElements', ['eirElementInfo']),
    ...mapGetters('filters', ['getTemplateFilter']),
    ...mapGetters('eirElements', ['getCurrentEirElementFilterId']),
    ...mapGetters(['isDesigner']),
    ...mapState('revit', ['syncStatus', 'builtInParams', 'builtInCategories']),
    connecting: (vm) => vm.syncStatus === 2,
    isLoading: (vm) =>
      vm.currentFilterStatus === 2 || vm.loading || vm.dictionariesAreLoading,
    filterId: (vm) => vm.getCurrentEirElementFilterId,
    additionalOptions: (vm) => ({
      builtInParams: vm.builtInParams,
      builtInCategories: vm.builtInCategories,
    }),
    blocks: (vm) => {
      const blocks = []
      let number = 1

      vm.currentFilter.conditions?.forEach((item) => {
        const index = blocks.findIndex((block) => block.uuid === item.blockUUID)
        if (index === -1) {
          blocks.push({
            uuid: item.blockUUID,
            number,
          })
          number++
        }
      })

      return blocks
    },
    blocksOptions: (vm) => ({
      list: {
        Or: 'Или',
        Exclude: 'Исключая',
        And: 'Найти',
      },
      key: 'operator',
      items: vm.blocks,
    }),
    captions: (vm) => {
      const model = vm.getFilterModel()

      return (
        model?.map((item, index) => {
          const operators = {
            Or: 'Или ',
            Exclude: 'Исключая ',
            And: 'И ',
          }
          const operator = index === 0 ? '' : operators[item.operator]

          const categories = {
            Element: 'элементы',
            Category: 'категории',
            Parameter: 'параметры',
          }
          const category = categories[item.category] ?? ''

          const properties = {
            Parameter:
              getProperty(undefined, item.property, vm.builtInParams) ??
              getProperty('Parameter', item.property),
            Category:
              getProperty(undefined, item.property, vm.builtInCategories) ??
              getProperty('Category', item.property),
            Element: getProperty('Element', item.property),
          }
          const property = properties[item.category] ?? ''

          function getProperty(key, property, items = FILTER_PROPERTY) {
            const array = key ? items?.[key] : items
            return array?.find(({ code }) => code === property)?.fullName
          }

          const conditions = {
            '=': 'строго равно',
            '≠': 'строго не равно',
            '>': 'больше',
            '<': 'меньше',
            '>=': 'больше либо равно',
            '<=': 'меньше либо равно',
            Contains: 'содержит',
            'Does Not Contains': 'не содержит',
            Duplicated: 'дублировано и равно',
            BeginsWith: 'начинается с',
            EndsWith: 'заканчивается на',
            NotBeginsWith: 'не начинается с',
            NotEndsWith: 'не заканчивается на',
            Included: 'включительно',
            Excluded: 'не включительно',
          }
          const condition = conditions[item.condition] ?? ''

          let caption = category ? `${operator} ${category}` : ''

          if (caption && property) caption += ` с названием «${property}»`

          if (caption && property && condition) {
            if (item.condition?.includes('cluded')) caption += ` ${condition}`
            else {
              caption += `, значение которых ${condition}`

              if (item.value) caption += ` «${item.value}»`
            }
          }

          return caption
        }) ?? []
      )
    },
  },
  watch: {
    eirElementInfo: {
      deep: true,
      immediate: true,
      async handler(value) {
        if (isEmpty(value)) return

        this.isTemplate = false
        this.blockUUID = null

        if (this.filterId) await this.getFilterById(this.filterId)
        else {
          this.resetCurrentFilter()
          this.updateServerStateFilter(this.currentFilter)
        }

        const [saprFilter] = Object.entries(SAPR_SYSTEMS).find(
          ([_, value]) => value.code === this.currentFilter?.sapr
        )
        if (saprFilter) this.selectedSapr = saprFilter
      },
    },
    currentFilterStatus(newValue, oldValue) {
      if (newValue !== 3 || newValue === oldValue) return

      this.reset()
    },
    filterModel: {
      deep: true,
      handler() {
        if (this.isTemplate) this.isTemplate = false
        if (this.isSandbox || this.designer) this.applyForSandbox()
      },
    },
  },
  async mounted() {
    if (this.builtInParams?.length && this.builtInCategories?.length) return

    this.dictionariesAreLoading = true

    await this.syncWithRevit()
    await this.getDictionaries()

    this.dictionariesAreLoading = false
  },
  beforeDestroy() {
    this.reset()
    this.resetCurrentFilter()
    this.updateServerStateFilter(this.currentFilter)
  },
  methods: {
    ...mapMutations('filters', [
      'addEmptyCondition',
      'updateCondition',
      'removeCondition',
      'resetCurrentFilter',
      'switchFilterStatus',
      'updateCurrentFilter',
      'updateServerStateFilter',
    ]),
    ...mapMutations('ui', ['triggerNotifySnackbar']),
    ...mapActions('filters', ['getFilterById', 'createFilter', 'updateFilter']),
    ...mapActions('revit', ['syncWithRevit', 'getDictionaries']),
    getFilterModel() {
      const conditions = this.currentFilter.conditions

      if (this.filterModel?.length) {
        const [first] = this.filterModel
        if (first.category && first.condition && first.property) {
          return this.filterModel
        } else {
          return conditions
        }
      } else {
        return conditions
      }
    },
    submit(conditions) {
      const payload = {
        eirElementId: this.$route.params.eirId,
        conditions,
        sapr: SAPR_SYSTEMS[this.selectedSapr]?.code ?? 1,
      }

      if (isNumber(this.filterId)) {
        payload.id = this.filterId
        this.updateFilter(payload)
      } else {
        this.createFilter(payload)
      }
    },
    reset() {
      this.switchFilterStatus(1)
      this.$refs.tableForm.reset()
      this.isTemplate = false
      this.blockUUID = null
    },
    /**
     * Сброс фильтра к сохранённому состоянию на сервере
     * */
    resetToServerState() {
      if (Object.keys(this.serverStateFilter).length) {
        this.updateCurrentFilter(cloneDeep(this.serverStateFilter))
      } else {
        this.resetCurrentFilter()
      }
    },
    /**
     * Получение доступа в зависимости от роли
     * */
    gettingAccess(row = null) {
      if (!this.isDesigner && !this.designer) return {}

      return {
        disableSaving: true, // отключить сохранение
        disableEditing: !row?.isMy, // отключить редактирование
        disableDeletion: !row?.isMy, // отключить удаление
      }
    },
    apply(conditions) {
      this.updateCurrentFilter({
        ...cloneDeep(this.currentFilter),
        conditions,
      })
      this.$emit('apply', {
        conditions,
      })
    },
    addBlock() {
      this.addEmptyCondition({
        model: { Operator: this.blockOperator },
        blockUUID: UUIDv4(),
      })
    },
    addRow() {
      this.addEmptyCondition({
        blockUUID: this.blockUUID,
      })
    },
    removeRow(rowIndex) {
      const condition = this.currentFilter.conditions[rowIndex]
      const nextCondition = this.currentFilter.conditions[rowIndex + 1]
      const modelUUID = condition?.modelUUID
      if (!modelUUID) return

      const isNotLastRowInTheBlock =
        nextCondition && nextCondition.blockUUID === condition.blockUUID

      if (
        (condition.operator !== 'And' && isNotLastRowInTheBlock) ||
        rowIndex === 0
      ) {
        this.updateCondition({
          index: rowIndex + 1,
          condition: { operator: condition.operator },
        })
      }

      this.removeCondition(rowIndex)
      this.blockUUID = null
    },
    checkIfNewBlock(index) {
      if (index === 0) return false

      const model = this.getFilterModel()

      return model?.[index].blockUUID !== model?.[index - 1].blockUUID
    },
    showModalSelectTemplate() {
      this.$refs.modalSelectTemplate.show()
    },
    showModalFilterTemplate() {
      this.$refs.modalFilterTemplate.show()
    },
    saveAsTemplate(name) {
      if (!this.$refs.tableForm.$refs.form.validate()) return

      const conditions = this.getFilterModel()

      this.createFilter({
        name,
        conditions,
        template: true,
      })
    },
    selectTemplate(templateId) {
      const conditions = this.getTemplateFilter(templateId)?.conditions
      if (!conditions) return

      this.reset()
      this.$refs.tableForm.resetToServerState()
      this.isTemplate = true

      this.apply(conditions)
    },
    applyForSandbox() {
      if (!this.$refs.tableForm.$refs.form.validate()) {
        this.$emit('update:valid', false)

        return
      }

      const conditions = this.getFilterModel()

      this.$emit('apply', { conditions })
      this.$emit('update:valid', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.add-block-select {
  max-width: 200px;
}

.add-block-button {
  min-width: 222px !important;
}
</style>
