<template>
  <div>
    <slot
      name="header"
      :disabled="isLoading"
      :hide="accessFn().disableAdding"
    />
    <v-form
      ref="form"
      v-model="valid"
      :disabled="isLoading"
      @submit.prevent="submit"
    >
      <v-progress-linear v-if="isLoading" color="primary" indeterminate />
      <div class="toolbar mx-auto">
        <v-expand-transition v-if="!hideWarning">
          <v-alert v-show="!disableSave" dense text type="warning" dismissible>
            Изменения не сохранены
          </v-alert>
        </v-expand-transition>
        <div class="d-flex mb-4 mt-2">
          <div class="slot-wrapper flex-grow-1 pr-4">
            <slot name="toolbar" />
          </div>
        </div>
      </div>
      <v-simple-table fixed-header dense class="table-form" height="100%">
        <template #default>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="`header-${header.value}`"
                class="text-left px-1"
                scope="col"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <TableFormRow
              v-for="(row, rowIndex) in items"
              :key="`row-${row.modelUUID}`"
              :headers="headers"
              :class="{
                'border-top': checkIfAnotherBlockRow(row, items[rowIndex - 1]),
                'border-bottom': checkIfAnotherBlockRow(
                  row,
                  items[rowIndex + 1]
                ),
              }"
            >
              <template
                v-for="(header, colIndex) in headers"
                #[`item-${header.value}`]
              >
                <TableFormCell
                  v-if="checkIfShowCell(header.value)"
                  :key="`cell-${row.modelUUID}-${header.value}`"
                  v-model="model[getFieldKey(row, header)]"
                  :uuid="row.modelUUID"
                  :header="header"
                  :field-component="header.component"
                  :field-options="getFieldOptions(row, header)"
                  :initial-value="getFieldInitialValue(row, header)"
                  :form-model.sync="model"
                  :editable="header.component !== 'span'"
                  :disabled="isLoading || accessFn(row).disableEditing"
                >
                  <div
                    v-if="
                      colIndex === 0 &&
                      checkIfAnotherBlockRow(row, items[rowIndex - 1]) &&
                      getBlockHeader(row)
                    "
                    class="row-header font-weight-bold"
                  >
                    {{ getBlockHeader(row) }}
                  </div>
                </TableFormCell>
                <template v-else>
                  <td
                    :key="`cell-${row.modelUUID}-${header.value}`"
                    style="text-align: right"
                  >
                    <v-tooltip
                      v-if="checkIfShowDeleteButton(row, rowIndex, colIndex)"
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          @click="removeRow(rowIndex, row.modelUUID)"
                          v-on="on"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </template>
                      <span>Удалить</span>
                    </v-tooltip>
                  </td>
                </template>
              </template>
            </TableFormRow>
          </tbody>
        </template>
      </v-simple-table>
      <div class="d-flex mt-8">
        <v-btn
          v-if="!accessFn().disableAdding && !customAddButton"
          color="primary"
          large
          elevation="10"
          :height="40"
          :disabled="isLoading"
          @click="addRow"
        >
          <v-icon class="pr-3">mdi-table-row-plus-after</v-icon>
          {{ addButtonText }}
        </v-btn>
        <slot
          name="custom-add-button"
          :disabled="isLoading"
          :hide="accessFn().disableAdding"
          :add-row-fn="addRow"
        />
        <div class="ml-auto">
          <v-btn
            color="primary"
            elevation="10"
            large
            outlined
            :disabled="disableSave"
            @click="resetToServerState"
          >
            Сбросить
          </v-btn>
          <slot
            name="additional-buttons"
            :disabled="isLoading"
            :hide="accessFn().disableAdding"
          />
          <v-btn
            v-if="!accessFn().disableSaving"
            class="ml-2"
            color="primary"
            elevation="10"
            large
            :disabled="disableSave"
            :loading="isLoading"
            @click="submit"
          >
            Сохранить
          </v-btn>
        </div>
      </div>
      <slot
        name="additional-actions"
        :disabled="isLoading"
        :hide="accessFn().disableAdding"
        :add-block-fn="addBlock"
      />
    </v-form>
    <slot name="footer" />
  </div>
</template>

<script>
/**
 * Для правильной работы формы у каждой строки должно быть поле modelUUID
 * (должен генерироваться в методе getModel класса, передающегося через itemModelClass)
 * */
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import TableFormRow from './TableFormRow'
import TableFormCell from './TableFormCell'

import { TAGS } from './constants'

export default {
  name: 'TableForm',
  components: { TableFormRow, TableFormCell },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedSapr: {
      type: String,
      default: 'revit',
    },
    serverStateItems: {
      type: Array,
      default: () => [],
    },
    itemModelClass: {
      type: Function,
      required: true,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hideWarning: {
      type: Boolean,
      default: false,
    },
    blocksOptions: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Функция должна возвращать объект с булевыми значениями
     * */
    accessFn: {
      type: Function,
      default: () => () => ({
        disableAdding: false, // отключить добавление
        disableSaving: false, // отключить сохранение
        disableEditing: false, // отключить редактирование
        disableDeletion: false, // отключить удаление
      }),
    },
    addButtonText: {
      type: String,
      default: 'Добавить',
    },
    customAddButton: {
      type: Boolean,
      default: false,
    },
    needModel: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    model: {},
    valid: false,
  }),
  computed: {
    rowsHaveBeenChanged: (vm) =>
      vm.serverStateItems?.length !== vm.items?.length ||
      vm.items?.findIndex((item) => item.isMy) !== -1,
    disableSave: (vm) =>
      vm.isLoading ||
      (!(vm.rowsHaveBeenChanged || !isEmpty(vm.model)) && !vm.canSave),
  },
  watch: {
    model: {
      handler(model) {
        if (this.needModel) this.$emit('update:model', this.prepareModel(model))
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getFieldKey(row, header) {
      return `row_${row.modelUUID}_cell_${header.value}`
    },
    getFieldOptions(row, header) {
      const defaultOptions = cloneDeep(
        TAGS[header.component]?.defaultOptions ?? {}
      )
      const options = cloneDeep(header.options ?? {})
      let initialValues = {}
      let bondWithKeys = {}

      if (header.bondWith) {
        /**
         * Передаём изначальные значения для доступа к ним из компонента поля
         * */
        initialValues = header.bondWith.reduce((acc, key) => {
          acc[key] = this.getFieldInitialValue(row, { value: key })
          return acc
        }, {})
        /**
         * Передаём список ключей для доступа к ним из компонента поля
         * */
        bondWithKeys = header.bondWith.reduce((acc, key) => {
          acc[key] = `row_${row.modelUUID}_cell_${key}`
          return acc
        }, {})
      }

      options.initialValues = initialValues
      options.bondWithKeys = bondWithKeys
      /**
       * Вычисляемые опции
       * (this нужен для использования $attrs)
       * */
      if (typeof options.items === 'function') {
        options.items = options.items.call(this, bondWithKeys, initialValues)
      }
      if (typeof options.label === 'function') {
        options.label = options.label.call(this)
      }

      if (this.selectedSapr === 'renga' && header.value === 'category')
        options.items = options.items.filter(
          (item) => item.value === 'Category'
        )

      return { ...defaultOptions, ...options }
    },
    getFieldInitialValue(row, header) {
      return row[header.value]
    },
    addBlock() {
      this.$emit('add-block')

      this.updateModel()
    },
    addRow() {
      this.$emit('add-row')

      this.updateModel()
    },
    removeRow(rowIndex, rowUUID) {
      if (!confirm('Удалить?')) return

      this.$emit('remove-row', rowIndex)
      this.cleanModelRow(rowUUID)

      this.updateModel()
    },
    cleanModelRow(UUID) {
      Object.keys(this.model).forEach((key) => {
        if (key.includes(UUID)) this.deleteValue(key)
      })
    },
    deleteValue(key) {
      this.model[key] = null

      delete this.model[key]
    },
    submit() {
      if (!this.$refs.form.validate()) return

      this.$emit('submit', this.prepareModel())
    },
    prepareModel() {
      let itemsByUUIDs = {}

      Object.keys(this.model).forEach((key) => {
        /**
         * ключи в модели: row_${row.modelUUID}_cell_${header.value}`
         * */
        const value = this.model[key]
        const split = key.split('_')
        const modelUUID = split[1]
        const headerValue = split[3]

        if (!itemsByUUIDs[modelUUID]) itemsByUUIDs[modelUUID] = {}

        itemsByUUIDs[modelUUID][headerValue] = value
      })

      return this.items.map((item) =>
        this.itemModelClass.getModel(itemsByUUIDs[item.modelUUID], item)
      )
    },
    reset() {
      this.model = {}
    },
    resetToServerState() {
      this.reset()
      this.$emit('reset-to-server-state')
      this.updateModel()
    },
    checkIfShowDeleteButton(row, rowIndex, colIndex) {
      return (
        !this.accessFn(row).disableDeletion &&
        !(rowIndex === 0 && (!this.items.length || this.items.length === 1)) &&
        !(rowIndex === 0 && colIndex === 0)
      )
    },
    checkIfShowCell(headerValue) {
      return headerValue !== 'actions'
    },
    checkIfAnotherBlockRow(currentRow, anotherRow) {
      return (
        (anotherRow && currentRow.blockUUID !== anotherRow.blockUUID) ||
        !anotherRow
      )
    },
    getBlockHeader(row) {
      const tableModel = this.prepareModel(this.model)
      const rowModel = tableModel.find(
        (item) => item.modelUUID === row.modelUUID
      )
      const fieldKey = this.blocksOptions.key
      const list = this.blocksOptions.list ?? {}

      const blockRow = rowModel ? rowModel : row
      const blockNumber =
        this.blocksOptions.items?.find(
          (item) => item.uuid === blockRow.blockUUID
        )?.number + '. ' ?? ''

      return `${blockNumber}${list[blockRow[fieldKey]] ?? ''}`
    },
    updateModel() {
      if (this.needModel) {
        setTimeout(
          () => this.$emit('update:model', this.prepareModel(this.model)),
          100
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.toolbar {
  max-width: 960px;

  .slot-wrapper {
    max-width: 500px;
  }
}

.table-form {
  width: 100%;

  th {
    padding: 1px;
    min-width: 80px;
    box-shadow: none !important;
  }

  td {
    padding: 1px;
    border-bottom: 0 !important;
  }

  &:deep(.v-data-table__wrapper) {
    overflow-y: hidden;
  }
}

.row-header {
  position: absolute;
  top: -20px;
}
</style>
