<script setup>
import { ref, defineExpose, nextTick, watch, computed, defineEmits } from 'vue'
import { useStore } from '@/store'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

import MyVirtualScroll from '@/components/MyVirtualScroll.vue'
import ListFilter from '@/components/ProjectEir/ListFilter.vue'
import ProductItem from '@/components/ProjectEir/ProductItem.vue'
import ModalParamList from '@/components/ProjectEir/ModalParamList.vue'
import ModalTableParamList from '@/components/ProjectEir/ModalTableParamList.vue'

defineExpose({ show })

const emit = defineEmits(['apply'])
const store = useStore()

const filterRef = ref(null)
const paramListRef = ref(null)
const tableParamListRef = ref(null)

const isLoading = ref(false)
const dialog = ref(false)

const products = computed(() =>
  store.state.catalogs.productList.map((item) => ({
    ...item,
    status: 'verified',
  }))
)
const saprElementList = ref([])
const eirElementId = ref(null)
const tableParams = ref([])

/**
 * Определения высот контейнера для виртуального скрола
 * */
const productItemHeight = 120
const productListHeight = ref(0)
const productListRef = ref(null)
const productListObserver = new ResizeObserver(([{ target }]) => {
  productListHeight.value = target.offsetHeight
})

const currentFilter = ref({
  price: { from: null, to: null },
  manufacturerList: [],
  searchString: '',
  paramList: [],
})
const initLimit = 20
const currentLimit = ref(initLimit)
const debounceChangeFilter = debounce((data) => changeFilter(data), 700)
async function changeFilter(data) {
  Object.assign(currentFilter.value, cloneDeep(data))
  currentLimit.value = initLimit
  isLoading.value = true
  await fetchProducts()
  isLoading.value = false
}

const isLoadingNewList = ref(false)
async function getNewList() {
  if (!currentLimit.value) return 0
  const start =
    currentLimit.value === initLimit ? initLimit : currentLimit.value
  isLoadingNewList.value = true
  const items = await store.dispatch('catalogs/appEndNewProductList', {
    ...currentFilter.value,
    start,
    limit: initLimit,
  })
  isLoadingNewList.value = false
  if (items.length < initLimit) {
    currentLimit.value = null
  } else {
    currentLimit.value += initLimit
  }
}

async function fetchProducts() {
  if (!currentLimit.value) return 0
  const items = await store.dispatch('catalogs/fetchProductList', {
    ...currentFilter.value,
    start: 0,
    limit: initLimit,
  })
  if (items.length < initLimit) {
    currentLimit.value = null
  }
}

function productClick(product) {
  paramListRef.value?.show(product, saprElementList, eirElementId)
}

function selectManufacturer(name) {
  if (filterRef.value?.setManufacturer) filterRef.value.setManufacturer(name)
}

function getValuesParams(name) {
  return saprElementList.value.reduce((array, object) => {
    const result = [...array]
    if (Object.hasOwn(object, name) && object[name].value !== '')
      result.push(object[name].value)
    return result
  }, [])
}

function show(saprElements, eirID, params) {
  tableParams.value = params
  saprElementList.value = saprElements
  eirElementId.value = eirID
  dialog.value = true

  nextTick(async () => {
    productListObserver.observe(productListRef.value)
    isLoading.value = true
    await fetchProducts()
    isLoading.value = false
  })
}

watch(
  () => dialog.value,
  (status) => {
    if (!status) {
      productListObserver.unobserve(productListRef.value)
      productListHeight.value = 0
      if (filterRef.value?.reset) filterRef.value.reset()
      store.commit('catalogs/reset')
    }
  }
)
</script>

<template>
  <div>
    <ModalParamList ref="paramListRef" @apply="emit('apply')" />
    <ModalTableParamList ref="tableParamListRef" @apply="emit('apply')" />
    <v-dialog v-model="dialog" persistent max-width="1280">
      <v-card>
        <v-card-title class="mb-2">
          Значения и продукты
          <v-spacer />
          <v-icon title="Закрыть" @click="dialog = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div class="left-content">
              <div
                v-if="tableParams?.length && saprElementList?.length"
                class="table-params"
              >
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <template v-for="{ value } in tableParams">
                          <th
                            v-if="getValuesParams(value)?.length"
                            :key="value"
                            style="border-bottom: 0; padding-left: 0"
                          >
                            {{ value }}
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="background-color: transparent">
                        <template v-for="{ value } in tableParams">
                          <td
                            v-if="getValuesParams(value)?.length"
                            :key="`${value}value`"
                            style="padding-left: 0"
                          >
                            <v-select
                              placeholder="Выберите значение"
                              :items="getValuesParams(value)"
                              dense
                            />
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div ref="productListRef" class="product-list">
                <template v-if="productListHeight">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="list-item-avatar-three-line@3"
                    :height="productItemHeight * 3"
                  />
                  <div v-else-if="!products?.length">
                    Продуктов не найдено. Попробуйте изменить параметры фильтра.
                  </div>
                  <MyVirtualScroll
                    v-else
                    class="virtual-scroll"
                    :items="products"
                    :height="productListHeight"
                    :item-height="productItemHeight"
                    @onScrolledToEnd="getNewList"
                  >
                    <template #default="{ item, index }">
                      <ProductItem
                        :key="index"
                        :product="item"
                        @click="productClick"
                        @showParamList="
                          (product) =>
                            tableParamListRef.show(
                              product,
                              saprElementList,
                              eirElementId
                            )
                        "
                        @selectManufacturer="selectManufacturer"
                      />
                    </template>
                  </MyVirtualScroll>
                </template>
                <v-progress-linear
                  v-if="isLoadingNewList"
                  class="progress-linear"
                  indeterminate
                  color="primary"
                />
              </div>
            </div>
            <div class="right-content">
              <ListFilter ref="filterRef" @change="debounceChangeFilter" />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.product-list {
  height: 100%;
  position: relative;
}

.progress-linear {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.virtual-scroll {
  padding-right: 8px;

  & :deep(.v-virtual-scroll__container) {
    position: relative;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bababa;
    border-radius: 4px;
    border: 0 none #ffffff;
  }
}

:deep(.v-dialog:not(.v-dialog--fullscreen)) {
  .v-sheet.v-card {
    display: flex;
    flex-direction: column;

    .v-card__text {
      height: 100%;
    }
  }
}

.left-content {
  width: 100%;
  display: flex;
  padding-right: 20px;
  flex-direction: column;
}

.right-content {
  width: 290px;
}
</style>
