<template>
  <component :is="wrapperTag" class="table-form-row">
    <slot v-for="header in headers" :name="`item-${header.value}`" />
  </component>
</template>

<script>
export default {
  name: 'TableFormRow',
  props: {
    wrapperTag: {
      type: String,
      default: 'tr',
    },
    headers: {
      type: Array,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.table-form-row {
  height: auto !important;
  background: rgba(0, 175, 161, 0.1);

  &.border-top {
    & :deep(td) {
      border-top: 35px white solid !important;

      &:first-child {
        position: relative;
      }
    }
  }
}
</style>
