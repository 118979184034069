export const TAGS = {
  'v-text-field': {
    defaultOptions: {
      'hide-details': true,
      dense: true,
      outlined: true,
    },
  },
  'v-select': {
    defaultOptions: {
      'hide-details': true,
      dense: true,
      outlined: true,
    },
    defaultValue: null,
  },
  'v-autocomplete': {
    defaultOptions: {
      'hide-details': true,
      dense: true,
      outlined: true,
    },
  },
  'v-switch': {
    defaultOptions: {
      'hide-details': true,
    },
  },
  span: {
    defaultOptions: {},
  },
}
