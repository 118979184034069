<template>
  <div>
    <v-data-table
      :key="tableReRender"
      v-model="selectedElementsModel"
      :loading="isLoading"
      :headers="computedHeaders"
      :items="filteredItems"
      :search="elementSearch"
      :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
      :hide-default-footer="filteredItems?.length <= 20"
      :items-per-page="20"
      :custom-filter="customFilter"
      :custom-sort="customSort"
      :show-select="!isSandbox"
      :mobile-breakpoint="0"
      :item-class="setRowBackground"
      :class="[{ 'is-loading': isLoading }]"
    >
      <template #top>
        <div class="sticky-line">
          <div class="d-flex align-center">
            <div class="d-flex flex-grow-1 pt-1 pr-2">
              <v-text-field
                v-model="elementSearch"
                label="Поиск по колонкам"
                outlined
                :hide-details="!isSandbox"
                dense
                clearable
                prepend-inner-icon="mdi-table-search"
                style="max-width: 500px"
              >
                <template v-if="!isSandbox" #append>
                  <DropdownFilter
                    :filter-list="filterList"
                    :switchers.sync="switchers"
                  />
                </template>
              </v-text-field>
              <component
                :is="!selectedElementsModel.length ? 'v-badge' : 'div'"
                v-if="!isSandbox"
                :title="
                  !selectedElementsModel.length
                    ? 'Для записи продукции выберете элементы САПРа'
                    : false
                "
                bordered
                color="warning"
                icon="mdi-exclamation"
                overlap
              >
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="primary"
                      elevation="10"
                      height="40"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mr-2">mdi-library-outline</v-icon>
                      Библиотека продукции
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="showModalCatalogs">
                      <v-list-item-title>Каталог IRMA</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showMyLibrary">
                      <v-list-item-title>
                        База &laquo;Петрович&raquo;
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </component>
            </div>
            <div v-if="!isSandbox" class="flex-grow-2 text-right">
              <v-btn
                color="primary"
                elevation="10"
                :disabled="!connected || !selectedFile || selecting"
                :loading="selecting"
                height="40"
                @click="
                  selectElements({
                    eirElementId: eirElement?.id,
                    notMatch: switchers.notMatch,
                  })
                "
              >
                <v-icon class="mr-2">mdi-selection-search</v-icon>
                Выделить элементы в САПРе
              </v-btn>
            </div>
            <slot name="header" />
          </div>
        </div>
        <div v-if="!isSandbox" class="d-flex my-2 mx-4 align-center">
          <div>
            <v-checkbox
              v-model="selectModel"
              :ripple="false"
              hide-details
              class="ma-0 mr-2"
              color="primary"
              :label="
                selectModel
                  ? 'Снять выделение на всех страницах'
                  : 'Выделить на всех страницах'
              "
            />
          </div>
          <div class="ml-auto">
            Элементов выделено на всех страницах:
            {{ selectedElementsModel?.length ?? 0 }}
          </div>
        </div>
      </template>

      <template #item.elementInfo="{ item }">
        <v-list-item class="px-0" two-line>
          <v-list-item-content class="px-0">
            <v-list-item-subtitle class="d-flex">
              <div class="text-overline" style="line-height: 1.2">
                {{ item.elementInfo.id }}
              </div>
              <v-menu offset-x open-on-hover top max-width="350px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item?.['Описание']?.value && item?.['URL']?.value"
                    v-bind="attrs"
                    class="ml-1"
                    color="primary"
                    small
                    v-on="on"
                  >
                    mdi-tooltip-text-outline
                  </v-icon>
                </template>
                <v-list dense class="py-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        v-if="item?.['Описание']?.value"
                        :title="item?.['Описание']?.value"
                      >
                        Описание: {{ item['Описание'].value | truncate(50) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item?.['URL']?.value"
                        :title="item?.['URL']?.value"
                      >
                        URL:
                        <a target="_blank" :href="item['URL'].value">
                          {{ item['URL'].value | truncate(50) }}
                        </a>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-subtitle>
            <v-list-item-title class="mb-1 d-flex">
              <div
                class="font-weight-bold"
                :title="item.elementInfo.familyName"
              >
                {{ item.elementInfo.familyName | truncate(truncateNumber) }}
              </div>
              <div
                :class="{ 'ml-2': !!item.elementInfo.familyName }"
                :title="item.elementInfo.typeName"
              >
                {{ item.elementInfo.typeName | truncate(truncateNumber) }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template
        v-for="param in paramsHeaders"
        #[`header.${param.value}`]="{
          header: { text, typeIcon, iconTitle, multipleFound },
        }"
      >
        <span :key="`header-${param.value}`">
          <span>{{ text }}</span>
          <v-icon
            v-if="typeIcon"
            color="warning"
            class="ml-1"
            :title="iconTitle"
          >
            {{ typeIcon }}
          </v-icon>
          <v-icon
            v-if="multipleFound"
            color="error"
            class="ml-1"
            title="Задвоен на одном из элементов"
          >
            mdi-circle-double
          </v-icon>
        </span>
      </template>

      <template
        v-for="param in paramsHeaders"
        #[`item.${param.value}`]="{ item }"
      >
        <div
          :key="`item-${item.id}-${param.value}`"
          class="d-flex flex-row align-center"
        >
          <v-menu
            v-model="showItems[`${item.id}-${param.value}`]"
            open-on-hover
            transition="slide-y-transition"
            bottom
            :disabled="!!item[param.value]?.match"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                style="min-width: 0"
                small
                text
                :class="['px-0', { 'no-pointer': item[param.value]?.match }]"
                :color="!item[param.value]?.match ? 'error' : null"
                v-on="on"
              >
                <span v-if="item[param.value]?.value">
                  {{ item[param.value]?.value }}
                </span>
                <v-icon
                  v-else
                  color="error"
                  :small="!item[param.value]?.present"
                >
                  {{
                    item[param.value]?.present
                      ? 'mdi-information-variant'
                      : 'mdi-help'
                  }}
                </v-icon>
              </v-btn>
            </template>
            <v-card max-height="400">
              <v-list class="py-0" dense>
                <template
                  v-for="(listItem, i) in getParamCheckResults(
                    item[param.value]
                  )"
                >
                  <v-list-item
                    :key="`item-${i}`"
                    :class="listItem.error ? 'error--text' : 'primary-text'"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ listItem.value }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="`divider-${i}`" class="divider" />
                </template>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template #item.sync="{ item }">
        <v-icon :color="item.match ? 'primary' : 'error'">
          {{
            item.match
              ? 'mdi-check-decagram-outline'
              : 'mdi-alert-decagram-outline'
          }}
        </v-icon>
      </template>
    </v-data-table>
    <ModalManufacturersCatalogs
      ref="modalManufacturersCatalogs"
      @change="++tableReRender"
      @apply="$emit('apply')"
    />
    <ModalMyLibrary
      ref="modalMyLibrary"
      :check-eir="checkEir"
      @change="++tableReRender"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import { VBadge } from 'vuetify/lib/components'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

import checkParameter from '@/utils/checkParameter'

import {
  SAPR_ELEMENTS_HEADERS_FIRST,
  SAPR_ELEMENTS_HEADERS_LAST,
} from '../constants'

import DropdownFilter from '@/components/DropdownFilter'
import ModalMyLibrary from './ModalMyLibrary.vue'
import ModalManufacturersCatalogs from '../../../components/ProjectEir/ModalManufacturersCatalogs.vue'

export default {
  name: 'SaprElements',
  components: {
    DropdownFilter,
    ModalMyLibrary,
    VBadge,
    ModalManufacturersCatalogs,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    eirElement: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    isSandbox: {
      type: Boolean,
      default: false,
    },
    getParamCheckResults: {
      type: Function,
      default: () => () => {},
    },
    checkEir: {
      type: Function,
      default: () => () => {},
    },
  },
  data: () => ({
    tableReRender: 0,
    elementSearch: '',
    switchers: {
      notMatch: false,
      notFilled: false,
      showWithLibrary: false,
      showWithoutLibrary: false,
    },
    filterList: [
      {
        name: 'Только несоответствующие требованию',
        id: 'notMatch',
      },
      {
        name: 'Только незаполненные',
        id: 'notFilled',
      },
      {
        name: 'Показать все с записанными свойствами из библиотеки',
        id: 'showWithLibrary',
      },
      {
        name: 'Показать все без записей из библиотеки',
        id: 'showWithoutLibrary',
      },
    ],
    showItems: {},
  }),
  computed: {
    ...mapState('revit', [
      'selectedSaprElements',
      'selectElementsStatus',
      'activeDocument',
      'syncStatus',
      'applyStatus',
    ]),
    ...mapGetters('revit', [
      'getParameterUnit',
      'checkIfElementIsFailed',
      'checkParameterExistence',
      'checkIfParamIsMultipleFound',
    ]),
    selectModel: {
      get() {
        return !!this.selectedSaprElements?.length
      },
      set(bool = false) {
        const elements = bool ? this.filteredItems : []

        this.selectSaprElements(elements)
      },
    },
    selectedElementsModel: {
      get() {
        return this.selectedSaprElements
      },
      set(elements = []) {
        if (elements?.length && isEqual(elements, this.selectedSaprElements))
          return

        this.selectSaprElements(elements)
      },
    },
    computedItems: (vm) =>
      vm.items?.map((item) => {
        const parameters = Object.values(item.parameters).reduce(
          (acc, param) => {
            acc[param.name] = {
              match: checkParameter(param),
              ...param,
            }

            return acc
          },
          {}
        )
        const cloneItem = cloneDeep(item)
        delete cloneItem.parameters

        return {
          ...cloneItem,
          elementInfo: {
            value: `${cloneItem.id}, ${cloneItem.typeName}, ${cloneItem.familyName}`,
            id: cloneItem.id,
            typeName: cloneItem.typeName,
            familyName: cloneItem.familyName,
          },
          ...parameters,
          link: item,
        }
      }) ?? [],
    filteredItems: (vm) =>
      vm.computedItems.filter((item) => {
        const notMatch = vm.switchers.notMatch ? !item.match : true
        const notFilled = vm.switchers.notFilled
          ? checkIfAnyParamWasNotFilled(item)
          : true
        const showWithLibrary = vm.switchers.showWithLibrary
          ? !!item?.['URL']?.value && !!item?.['Описание']?.value
          : true
        const showWithoutLibrary = vm.switchers.showWithoutLibrary
          ? !item?.['URL']?.value || !item?.['Описание']?.value
          : true

        function checkIfAnyParamWasNotFilled(item) {
          return Object.keys(item).some(
            (key) =>
              typeof item[key] === 'object' &&
              Object.hasOwnProperty.call(item[key], 'wasFilled') &&
              !item[key].wasFilled
          )
        }

        return notMatch && notFilled && showWithLibrary && showWithoutLibrary
      }),
    paramsHeaders: (vm) =>
      !vm.isSandbox
        ? vm.eirElement?.parameters?.map((param) => {
            const isType = vm.getIsTypeParameter(param)
            const isExist = vm.checkParameterExistence('name', param.name)
            let iconTitle = 'Параметр отсутствует'
            let typeIcon =
              vm.filteredItems?.length && !isExist ? 'mdi-table-off' : false

            if (isExist && isType) {
              typeIcon = 'mdi-alpha-t-circle-outline'
              iconTitle = 'Параметр типа'
            }

            return {
              text: `${param.name}${vm.getUnit(param)}`,
              value: param.name,
              typeIcon,
              iconTitle,
              multipleFound: vm.checkIfParamIsMultipleFound(
                vm.eirElement?.id,
                param.name
              ),
            }
          }) ?? []
        : [],
    computedHeaders: (vm) => [
      ...SAPR_ELEMENTS_HEADERS_FIRST,
      ...vm.paramsHeaders,
      ...(!vm.isSandbox ? SAPR_ELEMENTS_HEADERS_LAST : []),
    ],
    selecting: (vm) => vm.selectElementsStatus === 2,
    connected: (vm) => vm.syncStatus === 3,
    selectedFile: (vm) => vm.activeDocument?.filePath ?? '',
    truncateNumber: (vm) => (vm.isSandbox ? 1000 : 30),
  },
  watch: {
    applyStatus(value) {
      if (value !== 3 && value !== 0) return

      this.selectedElementsModel = []
    },
  },
  beforeDestroy() {
    this.selectModel = false
  },
  methods: {
    ...mapMutations('revit', ['selectSaprElements']),
    ...mapActions('revit', ['selectElements']),
    showModalCatalogs() {
      this.$refs.modalManufacturersCatalogs.show(
        this.selectedElementsModel,
        this.eirElement?.id,
        this.paramsHeaders
      )
    },
    showMyLibrary() {
      this.$refs.modalMyLibrary.show(
        this.selectedElementsModel,
        this.eirElement?.id
      )
    },
    getUnit({ name }) {
      const unit = this.getParameterUnit('name', name)

      return unit ? `, ${unit}` : ''
    },
    getStatusColor(item) {
      return item.match ? 'primary' : 'error'
    },
    customFilter(value, search) {
      if (
        typeof value === 'object' &&
        Object.hasOwnProperty.call(value, 'value')
      )
        value = value.value

      return (
        value &&
        typeof value !== 'boolean' &&
        value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1
      )
    },
    setRowBackground(item) {
      return this.checkIfElementIsFailed(item.elementInfo.id)
        ? 'my-row is-failed'
        : 'my-row'
    },
    getIsTypeParameter(param) {
      return (
        this.items?.[0]?.parameters?.find((item) => item.name === param.name)
          ?.isTypeParameter ?? false
      )
    },
    customSort(items, keySortBy, sortDesc) {
      const deepKey = keySortBy !== 'elementInfo' ? 'value' : 'typeName'
      const sorted = sortBy(items, (item) => item[keySortBy]?.[deepKey] ?? null)

      if (sortDesc[0]) return sorted.reverse()

      return sorted
    },
  },
}
</script>

<style lang="scss" scoped>
.divider:last-child {
  display: none;
}

.sticky-line {
  background-color: #fff;
  position: sticky;
  top: 74px;
  z-index: 1;

  & > div {
    padding: 8px 16px;
    margin: -1px -1px 0;
    border: thin solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    border-bottom: 0;
  }
}

:deep(.is-failed) {
  background-color: #fff8e1;
}

.is-loading {
  & :deep(.my-row) {
    filter: blur(5px);
    pointer-events: none;
  }
}

:deep(.no-pointer) {
  pointer-events: none;
}
</style>
