<template>
  <v-dialog v-model="dialog" persistent max-width="991">
    <v-card>
      <v-progress-linear v-if="isLoading" color="primary" indeterminate />
      <v-card-title class="mb-2">
        Библиотека продукци
        <v-spacer />
        <v-icon :disabled="isLoading" title="Закрыть" @click="dialog = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="!saprIds.length" class="mb-4" dense type="warning">
          Для записи продукции <strong>выберете</strong> элементы САПРа
        </v-alert>
        <v-text-field
          v-model.trim="search"
          class="mb-4"
          label="Поиск по наименованию"
          outlined
          dense
          single-line
          hide-details
          :disabled="isLoading"
          @input="debounceRequest"
        />
        <v-card outlined>
          <v-data-table
            loading-text="Загрузка... Пожалуйста, подождите"
            :loading="isTableLoading"
            :headers="MY_LIBRARY_COLUMNS"
            :items="rows"
            :server-items-length="total"
            :items-per-page="15"
            :options.sync="tableOptions"
            :mobile-breakpoint="0"
            sort-desc
            dense
          >
            <template #item.title="{ item }">
              <span :title="item.title">{{ item.title | truncate(60) }}</span>
            </template>
            <template #item.url="{ item }">
              <a target="_blank" :href="item.url" :title="item.url">
                {{ item.url | truncate(50) }}
              </a>
            </template>
            <template #item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    :title="
                      !saprIds.length
                        ? 'Для записи продукции выберете элементы САПРа'
                        : false
                    "
                  >
                    <v-icon
                      class="mr-2"
                      size="17"
                      v-bind="attrs"
                      :disabled="!saprIds.length || isLoading"
                      v-on="on"
                      @click="save(item)"
                    >
                      mdi-content-save-move-outline
                    </v-icon>
                  </div>
                </template>
                <span>Записать на выбранные элементы</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'

import { vTableOptionsPrepare } from '@/utils/storeHelpers'
import { MY_LIBRARY_COLUMNS } from '@/views/ProjectEir/constants'

export default {
  name: 'ModalMyLibrary',
  props: {
    checkEir: {
      type: Function,
      default: () => () => {},
    },
  },
  data: () => ({
    total: 0,
    rows: [],
    saprIds: [],
    eirId: '',
    search: '',
    dialog: false,
    isLoading: false,
    isTableLoading: false,
    tableOptions: {},
    MY_LIBRARY_COLUMNS,
  }),
  computed: {
    ...mapState('eirElements', ['eirElementInfo']),
  },
  watch: {
    tableOptions(_, oldOptions) {
      if (!Object.keys(oldOptions).length && !this.search) return
      this.request()
    },
  },
  methods: {
    ...mapActions('eirInstances', ['searchInCostx']),
    ...mapActions('revit', ['applyParamsRevit']),
    async save({ title, url }) {
      this.isLoading = true
      await this.applyParamsRevit({
        parameters: [
          { name: 'Описание', value: title, overwriteExistingValue: true },
          { name: 'URL', value: url, overwriteExistingValue: true },
        ],
        elementsIds: this.saprIds,
        eirElementId: this.eirId,
      })
      await this.checkEir(this.eirElementInfo)
      this.isLoading = false
      this.dialog = false
      this.$emit('change')
    },
    show(saprEls = [], eirId) {
      this.rows = []
      this.search = ''
      this.eirId = eirId
      this.dialog = true
      this.saprIds = saprEls.map(({ id }) => id)
      const [el] = saprEls.filter((el) => !!el?.['Описание']?.value)
      if (el?.['Описание']?.value) {
        this.search = el['Описание'].value
        if (Object.keys(this.tableOptions).length) this.request()
      }
    },
    debounceRequest: debounce(function () {
      this.request()
    }, 400),
    async request() {
      if (!this.search) {
        this.rows = []
        this.total = 0
        return
      }
      this.isTableLoading = true
      const { offset, limit } = vTableOptionsPrepare(this.tableOptions)
      const { data, metadata } = await this.searchInCostx({
        title: this.search,
        start: offset,
        limit: limit === 0 ? 10000 : limit,
      })
      this.rows = data
      this.total = metadata.total
      this.isTableLoading = false
    },
  },
}
</script>
