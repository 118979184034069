export const formatPrice = (value = 0, locale = 'ru-RU', options = {}) => {
  const opts = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }

  let number = +(value ?? 0)
  if (isNaN(number)) number = 0

  let string = number.toLocaleString(locale, opts)
  /**
   * Fix for '-0'
   * */
  if (number === 0 && string[0] === '-') string = string.slice(1)

  return string
}
