var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("header",null,{"disabled":_vm.isLoading,"hide":_vm.accessFn().disableAdding}),_c('v-form',{ref:"form",attrs:{"disabled":_vm.isLoading},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c('div',{staticClass:"toolbar mx-auto"},[(!_vm.hideWarning)?_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disableSave),expression:"!disableSave"}],attrs:{"dense":"","text":"","type":"warning","dismissible":""}},[_vm._v(" Изменения не сохранены ")])],1):_vm._e(),_c('div',{staticClass:"d-flex mb-4 mt-2"},[_c('div',{staticClass:"slot-wrapper flex-grow-1 pr-4"},[_vm._t("toolbar")],2)])],1),_c('v-simple-table',{staticClass:"table-form",attrs:{"fixed-header":"","dense":"","height":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:`header-${header.value}`,staticClass:"text-left px-1",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.items),function(row,rowIndex){return _c('TableFormRow',{key:`row-${row.modelUUID}`,class:{
              'border-top': _vm.checkIfAnotherBlockRow(row, _vm.items[rowIndex - 1]),
              'border-bottom': _vm.checkIfAnotherBlockRow(
                row,
                _vm.items[rowIndex + 1]
              ),
            },attrs:{"headers":_vm.headers},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header,colIndex){return {key:`item-${header.value}`,fn:function(){return [(_vm.checkIfShowCell(header.value))?_c('TableFormCell',{key:`cell-${row.modelUUID}-${header.value}`,attrs:{"uuid":row.modelUUID,"header":header,"field-component":header.component,"field-options":_vm.getFieldOptions(row, header),"initial-value":_vm.getFieldInitialValue(row, header),"form-model":_vm.model,"editable":header.component !== 'span',"disabled":_vm.isLoading || _vm.accessFn(row).disableEditing},on:{"update:formModel":function($event){_vm.model=$event},"update:form-model":function($event){_vm.model=$event}},model:{value:(_vm.model[_vm.getFieldKey(row, header)]),callback:function ($$v) {_vm.$set(_vm.model, _vm.getFieldKey(row, header), $$v)},expression:"model[getFieldKey(row, header)]"}},[(
                    colIndex === 0 &&
                    _vm.checkIfAnotherBlockRow(row, _vm.items[rowIndex - 1]) &&
                    _vm.getBlockHeader(row)
                  )?_c('div',{staticClass:"row-header font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getBlockHeader(row))+" ")]):_vm._e()]):[_c('td',{key:`cell-${row.modelUUID}-${header.value}`,staticStyle:{"text-align":"right"}},[(_vm.checkIfShowDeleteButton(row, rowIndex, colIndex))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.removeRow(rowIndex, row.modelUUID)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Удалить")])]):_vm._e()],1)]]},proxy:true}})],null,true)})}),1)]},proxy:true}])}),_c('div',{staticClass:"d-flex mt-8"},[(!_vm.accessFn().disableAdding && !_vm.customAddButton)?_c('v-btn',{attrs:{"color":"primary","large":"","elevation":"10","height":40,"disabled":_vm.isLoading},on:{"click":_vm.addRow}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-table-row-plus-after")]),_vm._v(" "+_vm._s(_vm.addButtonText)+" ")],1):_vm._e(),_vm._t("custom-add-button",null,{"disabled":_vm.isLoading,"hide":_vm.accessFn().disableAdding,"addRowFn":_vm.addRow}),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"color":"primary","elevation":"10","large":"","outlined":"","disabled":_vm.disableSave},on:{"click":_vm.resetToServerState}},[_vm._v(" Сбросить ")]),_vm._t("additional-buttons",null,{"disabled":_vm.isLoading,"hide":_vm.accessFn().disableAdding}),(!_vm.accessFn().disableSaving)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","elevation":"10","large":"","disabled":_vm.disableSave,"loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v(" Сохранить ")]):_vm._e()],2)],2),_vm._t("additional-actions",null,{"disabled":_vm.isLoading,"hide":_vm.accessFn().disableAdding,"addBlockFn":_vm.addBlock})],2),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }