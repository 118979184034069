<script setup>
import Vue, { computed, defineExpose, ref, watch, defineEmits } from 'vue'
import { useStore } from '@/store'
import {
  checkIfCanCreateParam,
  checkIfCustomReadonly,
  checkIfShared,
} from '@/utils/otherHelpers'

import OptionsSlider from '@/views/ProjectEir/partials/OptionsSlider.vue'
import ParamFieldOptions from '@/components/ProjectEir/ParamFieldOptions.vue'

defineExpose({ show })

const emit = defineEmits(['apply'])
const store = useStore()

const dialog = ref(false)
const isLoading = ref(false)
const productParams = ref([])
const productUrl = ref(null)
const formData = ref({})
const options = ref({})
const selectedAction = ref({})
const saprElList = ref(null)
const eirElId = ref(null)
const params = computed(() => [
  ...(store.state.parameters?.parameters ?? []).map(prepareParam),
  ...addedParams.value,
])
const addedParams = ref([])
const buttonDisabled = computed(() => !saprElList.value?.length)

function show(product, saprElementList, eirElementId) {
  saprElList.value = saprElementList
  eirElId.value = eirElementId
  productUrl.value = product.url
  productParams.value = product.parameters.filter(
    ({ name }) => name !== undefined && name?.trim() !== ''
  )
  params.value.forEach(initParam)
  dialog.value = true
}

function initParam(param) {
  Vue.set(formData.value, param.id, { productParam: null, value: null, param })
  Vue.set(selectedAction.value, param.id, null)
  Vue.set(options.value, param.id, {
    overwriteExistingValue: true,
    writeDown: true,
  })
}

function changeParamAction(action, name) {
  selectedAction.value[name] = action
}

async function send() {
  isLoading.value = true

  const parameters = []
  const paramsToCreate = []

  if (productUrl.value) {
    parameters.push({
      name: 'URL',
      value: productUrl.value,
      overwriteExistingValue: true,
    })
  }

  Object.keys(formData.value).forEach((key) => {
    const object = formData.value[key]
    if (!object.value) return

    if (selectedAction.value[key] === 'new') paramsToCreate.push(object.param)

    parameters.push({
      name: object.param.name,
      value: object.value,
      ...options.value[key],
    })
  })

  await Promise.all(
    paramsToCreate.map((parameter) =>
      store.dispatch('revit/createParamRevit', {
        parameter,
        eirElementId: eirElId.value,
      })
    )
  )
  await store.dispatch('revit/applyParamsRevit', {
    parameters,
    elementsIds: saprElList.value.map(({ id }) => id),
    eirElementId: eirElId.value,
  })

  store.commit('revit/resetCreateStatus', eirElId.value)
  emit('apply')

  isLoading.value = false
  dialog.value = false
}

function rules(param, value) {
  if (value === '' && param.is_required) return 'Обязательно к заполнению'
  if (param.check_regexp && !new RegExp(param.check_regexp).test(value))
    return 'Не пройдена валидация'
  return true
}

function getParamCreatingStatus(param) {
  return store.getters['revit/getParamCreatingStatus'](
    eirElId.value,
    param.name
  )
}
function checkIfParamIsNotPresent(param) {
  return store.getters['revit/checkIfParamIsNotPresent'](
    eirElId.value,
    param.name
  )
}
function checkIfParamIsReadonly(param) {
  return store.getters['revit/checkIfParamIsReadonly'](
    eirElId.value,
    param.name
  )
}

function prepareParam(param) {
  const readOnly = checkIfCustomReadonly(param) || checkIfParamIsReadonly(param)
  return {
    id: param.id,
    name: param.name,
    value: null,
    saprFields: param.sapr_parameters,
    sections: param.sections.map(({ id }) => id),
    overwriteExistingValue: true,
    writeDown: true,
    readOnly,
    isShared: checkIfShared(param),
    canCreate:
      getParamCreatingStatus(param) !== 3 &&
      checkIfParamIsNotPresent(eirElId.value, param) &&
      checkIfCanCreateParam(param),
    notPresentOnAnySelectedEl: checkIfParamIsNotPresent(param),
  }
}

function addNewParam() {
  const paramModel = {
    id: Date.now() + params.value.length,
    name: null,
    value: null,
    saprFields: {
      revitDataType: 'Text',
      revitGuid: '---------',
      revitIsReadonly: false,
      revitIsShared: false,
      revitIsSystem: '',
      revitParameterGroup: 'PG_GENERAL',
      revitType: 'Экземпляр',
    },
    sections: [],
    overwriteExistingValue: true,
    writeDown: true,
    readOnly: false,
    isShared: true,
    canCreate: true,
    notPresentOnAnySelectedEl: true,
  }
  addedParams.value.push(paramModel)
  initParam(paramModel)
  changeParamAction('new', paramModel.id)
}

watch(
  () => dialog.value,
  (status) => {
    if (!status) {
      formData.value = {}
      selectedAction.value = {}
    }
  }
)
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="875">
    <v-card v-if="dialog">
      <v-progress-linear v-if="isLoading" color="primary" indeterminate />
      <v-card-title class="mb-2">
        Запись параметров
        <v-spacer />
        <v-icon title="Закрыть" @click="dialog = false"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <p>
          Выберите или измените параметры, в которые необходимо записать
          значения
        </p>
        <v-simple-table>
          <thead>
            <tr>
              <th style="width: 25%">Наименование параметра</th>
              <th style="width: 30%">Параметр экземпляра</th>
              <th style="width: 120px">Опции</th>
              <th>Значение экз.</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="param in params" :key="param.id">
              <td>
                <template v-if="addedParams.includes(param)">
                  <v-text-field
                    v-model="param.name"
                    :rules="[(v) => rules(param, v)]"
                    placeholder="Введите наименование"
                    dense
                    hide-details
                    outlined
                  />
                </template>
                <div v-else style="line-height: 1.2">
                  {{
                    param.name +
                    (param.symbol ? `(${param.symbol})` : '') +
                    (param.is_required ? '*' : '')
                  }}
                </div>
              </td>
              <td>
                <template v-if="param.name">
                  <div v-if="!selectedAction[param.id]">
                    <v-icon
                      class="mr-2"
                      color="primary"
                      size="20"
                      title="Сопоставить с существующим"
                      @click="changeParamAction('select', param.id)"
                    >
                      mdi-chevron-right-box
                    </v-icon>
                    <v-icon
                      v-if="param.canCreate"
                      color="grey"
                      size="20"
                      title="Создать новый"
                      @click="changeParamAction('new', param.id)"
                    >
                      mdi-plus-box
                    </v-icon>
                  </div>
                  <v-select
                    v-else-if="selectedAction[param.id] === 'select'"
                    v-model="formData[param.id].productParam"
                    :items="productParams"
                    item-value="name"
                    item-text="name"
                    return-object
                    dense
                    hide-details
                    outlined
                    append-outer-icon="mdi-close"
                    @click:append-outer="
                      selectedAction[param.id] = null
                      formData[param.id].productParam = null
                    "
                    @change="(v) => (formData[param.id].value = v.value)"
                  />
                  <div
                    v-else-if="selectedAction[param.id] === 'new'"
                    class="d-flex align-center"
                    style="
                      line-height: 1.2;
                      white-space: nowrap;
                      font-size: 12px;
                    "
                  >
                    Создать новый <br />параметр
                    <v-icon
                      v-if="!addedParams.includes(param)"
                      class="ml-1"
                      size="20"
                      @click="selectedAction[param.id] = null"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                </template>
              </td>
              <td>
                <OptionsSlider
                  v-if="param.name"
                  :key="param.id"
                  class="option-slider"
                  :param="param"
                  @change="(obj) => (options[param.id] = { ...obj })"
                />
              </td>
              <td>
                <ParamFieldOptions
                  v-if="param.name && formData[param.id].productParam?.name"
                  v-model="formData[param.id].value"
                  :param="formData[param.id].productParam"
                  :form-data="formData"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-btn class="mt-4" outlined color="primary" small @click="addNewParam">
          <v-icon left> mdi-plus </v-icon>
          Добавить параметр
        </v-btn>
      </v-card-text>
      <v-card-actions class="pb-6 pr-6">
        <v-spacer />
        <v-btn elevation="0" class="btn" @click="dialog = false">
          Отменить
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="btn"
          :disabled="buttonDisabled"
          @click="send"
        >
          Записать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
:deep(.v-dialog) {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bababa;
    border-radius: 4px;
    border: 0 none #ffffff;
  }
}

.option-slider {
  padding-top: 12px;

  &::v-deep {
    .v-slider__thumb {
      left: -3px;
    }

    .v-slider--horizontal {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
}

.btn {
  text-transform: uppercase;
  font-weight: 400;
}
</style>
