<template>
  <div v-if="!isLoading">
    <Back @click="$router.back()">Назад</Back>

    <DashboardEirHeader
      :title="name"
      caption="Дашборд: требование"
      :updated-at="updatedAt"
      :checking="checking"
      :selected-file="selectedFile"
      :progress="eirProgress"
    >
      <template #actions>
        <v-menu
          v-if="description"
          v-model="showDescription"
          class="elevation-0"
          open-on-hover
          transition="slide-y-transition"
          bottom
          max-width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              icon
              small
              title="Описание"
              color="primary"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Описание</v-card-title>
            <v-card-text>
              {{ description }}
            </v-card-text>
          </v-card>
        </v-menu>
        <v-btn
          text
          icon
          small
          title="Скачать отчет для Excel, .xlsx"
          color="primary"
          class="ml-1"
          @click="exportToXLSX"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
        </v-btn>
      </template>
    </DashboardEirHeader>

    <div class="d-sm-flex align-sm-end" style="min-height: 54px">
      <v-tabs ref="tabs" v-model="tab" class="tabs order-sm-0">
        <v-tab>В проекте</v-tab>
        <v-tab>
          Параметры требования ({{ eirElementInfo.parameters?.length }})
        </v-tab>
      </v-tabs>
    </div>
    <v-divider class="mb-3" />

    <v-tabs-items v-model="tab" style="overflow: initial">
      <v-tab-item>
        <v-expansion-panels
          v-model="selectedPanels"
          accordion
          flat
          focusable
          hover
          popout
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header color="rgba(0, 175, 161, 0.05)" ripple>
              <FilterHeader
                ref="filterHeader"
                :eir-element="eirElementInfo"
                :disabled="!filterFormIsValid"
                :conditions="filterConditions"
                class="pr-2"
              />
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card outlined height="100%" class="pa-2">
                <SimpleFilter
                  ref="simpleFilter"
                  class="mb-10"
                  designer
                  :loading="checking"
                  :valid.sync="filterFormIsValid"
                  @apply="({ conditions }) => (filterConditions = conditions)"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="my-2">
            <v-expansion-panel-header color="rgba(0, 175, 161, 0.05)" ripple>
              <h3>Элементы САПРа</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card outlined height="100%">
                <SaprElements
                  ref="saprElements"
                  :is-loading="isLoading || applyStatus === 2 || checking"
                  :items="revitElements"
                  :eir-element="eirElementInfo"
                  :get-param-check-results="getParamCheckResults"
                  :check-eir="checkEir"
                  @apply="applyFilter"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="rgba(0, 175, 161, 0.05)" ripple>
              <div class="d-flex flex-column">
                <h3>Экземпляры</h3>
                <div class="mt-2 mr-2">
                  Здесь можно записать параметры в САПР или создать
                  отсутствующие (не забудьте создать новый экземпляр, если
                  параметры в требованиях были изменены)
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card outlined height="100%">
                <TabInstances @apply="applyFilter" />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>

      <v-tab-item>
        <TabParameters designer class="mb-10" :set-id="setId" />
      </v-tab-item>
    </v-tabs-items>
  </div>
  <v-progress-linear v-else class="loader" indeterminate color="primary" />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import * as XLSX from 'xlsx/xlsx.mjs'
import cloneDeep from 'lodash/cloneDeep'

import Back from '@/components/Back'
import TabParameters from '@/components/TabParameters'

import SimpleFilter from '@/components/SimpleFilter/SimpleFilter'

import TabInstances from '@/views/ProjectEir/partials/TabInstances'
import SaprElements from '@/views/ProjectEir/partials/SaprElements'
import FilterHeader from '@/views/ProjectEir/partials/FilterHeader'
import DashboardEirHeader from '@/views/ProjectEir/partials/DashboardEirHeader'
import { keysToCamelCaseDeep } from '@/utils/renameKeys'
import { SAPR_FIELDS_LABELS } from '@/constants'

export default {
  name: 'ProjectEir',
  components: {
    Back,
    TabParameters,
    SimpleFilter,
    TabInstances,
    SaprElements,
    FilterHeader,
    DashboardEirHeader,
  },
  data: () => ({
    tab: 0,
    isLoading: false,
    showDescription: false,
    selectedPanels: [1, 2],
    filterFormIsValid: true,
    filterConditions: [],
  }),
  computed: {
    ...mapState('projects', ['project']),
    ...mapState('eirElements', ['eirElementInfo']),
    ...mapState('parameters', ['parameters']),
    ...mapState('revit', [
      'activeDocument',
      'revitElementsCount',
      'progress',
      'applyStatus',
      'failedElementsIds',
      'activeDocument',
    ]),
    ...mapState('filters', ['currentFilter']),
    ...mapGetters('revit', ['getElementsById', 'getCheckStatusById']),
    ...mapGetters('eirElements', ['getCurrentEirElementFilterId']),
    filterId: (vm) => vm.getCurrentEirElementFilterId,
    name: (vm) => vm.eirElementInfo.name,
    description: (vm) => vm.eirElementInfo.description,
    updatedAt: (vm) => vm.eirElementInfo.updated_at,
    setId: (vm) => vm.eirElementInfo.eir_set,
    eirId: (vm) => vm.$route.params.eirId,
    revitElements: (vm) => vm.getElementsById(vm.eirId),
    checking: (vm) => vm.getCheckStatusById(vm.eirId) === 2,
    /*
     * Нет элементов, подходящих под фильтр, поэтому считаем, что требованию соответствует
     * */
    eirProgress: (vm) =>
      vm.revitElements?.length === 0 ? 100 : vm.progress[vm.eirId],
    selectedFile: (vm) => vm.activeDocument?.filePath ?? '',
  },
  watch: {
    parameters() {
      if (this.$refs.tabs) this.$refs.tabs.callSlider()
    },
  },
  async mounted() {
    this.isLoading = true

    await this.fetchProject(this.$route.params.projectId)
    await this.fetchEir(this.eirId)
    // TODO: избавиться от лимита, должна быть пагинация, либо все
    await this.fetchParams({
      filters: { eir: this.eirId, limit: 200 },
    })
    await this.syncWithRevit()

    if (this.filterId) await this.getFilterById(this.filterId)

    this.isLoading = false
  },
  beforeDestroy() {
    this.setFailedElementsIds()
  },
  methods: {
    ...mapActions('filters', ['getFilterById']),
    ...mapActions('eirElements', ['fetchEir']),
    ...mapActions('parameters', ['fetchParams']),
    ...mapActions('revit', ['syncWithRevit']),
    ...mapActions('projects', ['fetchProject']),
    ...mapMutations('revit', ['setFailedElementsIds']),
    checkEir(eirElement, revitFilter = this.currentFilter) {
      return this.$refs.filterHeader.checkEir(eirElement, revitFilter)
    },
    getParamCheckResults(item) {
      let results = [
        {
          value: `Присутствует: ${item?.present ? 'Да' : 'Нет'}`,
          error: !item?.present,
        },
      ]

      if (!item) return results

      const saprFields = item.saprFieldsFullness
      const labels = keysToCamelCaseDeep(SAPR_FIELDS_LABELS)
      labels.revitGUID = labels.revitGuid

      const variantList = {
        sharedParameter: 0,
        systemParameter: 1,
        parameter: 2,
      }
      const variant = variantList[item.type] ?? 2
      const keys = [
        [
          'revitGuid',
          'revitType',
          'revitDataType',
          'revitIsShared',
          'revitIsSystem',
          'revitIsReadonly',
        ],
        ['revitIsShared', 'revitIsSystem', 'revitIsReadonly'],
        ['revitDataType', 'revitIsShared', 'revitIsSystem', 'revitIsReadonly'],
      ]
      const currentKeys = keys[variant]

      if (item.present) {
        if (!item.wasFilled)
          results.push({
            value: 'Не заполнен',
            error: true,
          })
        if (item.multipleFound)
          results.push({
            value: 'Задвоен',
            error: true,
          })

        results = [
          ...results,
          ...Object.keys(saprFields)
            .map((key) => {
              if (currentKeys.includes(key))
                return {
                  value: `${labels[key]}: ${
                    saprFields[key] ? 'Соответствует' : 'Не соответствует'
                  }`,
                  error: !saprFields[key],
                }
            })
            .filter((field) => field),
        ]
      }

      return results
    },
    applyFilter() {
      const conditions = this.filterFormIsValid
        ? this.filterConditions
        : this.currentFilter.conditions

      this.checkEir(this.eirElementInfo, { conditions })
    },
    exportToXLSX() {
      /*
       * Требование
       * */
      const count = this.revitElementsCount[this.eirId]
      const elsLength = this.getElementsById(this.eirId)?.length
      const element = [
        '',
        this.eirElementInfo.name?.replaceAll(',', ';'),
        `${count?.countMatch ?? '—'} из ${elsLength ?? '—'}`,
        `${count?.countPresent ?? '—'} из ${
          this.eirElementInfo.parameters?.length ?? 0
        }`,
        this.eirProgress,
      ]
      /*
       * Элементы САПРА с параметрами и заголовками
       * */
      const filtered = this.$refs.saprElements?.computedItems?.filter(
        (item) => !item.match
      )
      let headers = null
      const mapped = []

      filtered.map((e) => {
        const info = `${e.elementInfo.familyName} / ${e.elementInfo.typeName}`

        let parameters = cloneDeep(e)
        delete parameters.category
        delete parameters.familyName
        delete parameters.elementInfo
        delete parameters.typeName
        delete parameters.link
        delete parameters.match
        delete parameters.present
        delete parameters.id

        if (!headers)
          headers = Object.keys(parameters).map((key) =>
            parameters[key].name.replaceAll(',', ';')
          )

        let emptyValAndCheck = true
        const checkResults = []
        const mappedParameters = Object.keys(parameters).map(
          (key, colIndex) => {
            /**
             * Формирование и сборка результатов проверки параметра
             * */
            let arrayValue = []
            arrayValue.push(
              ...this.getParamCheckResults(parameters[key])
                .filter(({ error }) => error)
                .map(({ value }) => value)
            )
            arrayValue = arrayValue.filter((v) => !!v)

            /**
             * Если есть ошибки, то не пропускаем элемент и добавляем в выгрузку
             * */
            if (arrayValue.length) {
              emptyValAndCheck = false
            } else {
              return ''
            }

            const first = arrayValue.shift()

            if (arrayValue.length) {
              arrayValue.forEach((text, i) => {
                const temp = []
                const currentColIndex = colIndex + 2 // +2 это пропуск колонок (ID, Элемент)
                temp[currentColIndex] = text
                if (!checkResults[i]) checkResults[i] = temp
                else checkResults[i][currentColIndex] = text
              })
            }

            return first ?? ''
          }
        )

        if (emptyValAndCheck) return

        mapped.push([
          e.elementInfo.id,
          info.replaceAll(',', ';'),
          ...mappedParameters,
        ])

        /**
         * Добавляем результаты проверок на новые строки
         * */
        if (checkResults.length) {
          checkResults.forEach((array) => {
            if (array.length) {
              mapped.push(array)
            }
          })
        }
      })

      /*
       * Итоговые строки
       * */
      const rows = [
        [
          '',
          'Название файла, выбранного в момент составления отчета',
          'Название проекта',
        ],
        [
          '',
          this.activeDocument.title,
          this.project.name?.replaceAll(',', ';'),
        ],
        [
          '',
          'Название требования',
          'Кол-во элементов САПРА (соотв. требованию / подходящие под фильтр)',
          'Кол-во параметров (на элементах САПРа / все)',
          'Соответствие %',
        ],
        element,
        ['Id', 'Элемент', ...(headers ?? [])],
        ...mapped,
      ]
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.aoa_to_sheet(rows)

      let cols = [{ wpx: 80 }, { wpx: 400 }]
      for (let i = 1; i <= (headers?.length ?? 1); i++) cols.push({ wpx: 200 })
      cols.push({ wpx: 85 })

      worksheet['!cols'] = cols
      worksheet['!rows'] = [{ hpx: 30 }, {}, { hpx: 40 }, {}, { hpx: 40 }]

      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        'Отчет по конкретному требованию'
      )
      XLSX.writeFile(workbook, 'irma-eir-report.xlsx')
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.v-expansion-panel-header--active::before) {
  opacity: 0 !important;
}

:deep(.v-expansion-panels--popout > .v-expansion-panel) {
  max-width: 100%;
}

:deep(.v-expansion-panel-content__wrap) {
  padding: 0 !important;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
}

.desc {
  font-size: 14px;

  &--small {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
