<template>
  <div>
    <v-data-table
      loading-text="Загрузка... Пожалуйста, подождите"
      :headers="computedHeaders"
      :items="computedInstances"
      :loading="isLoading"
      :items-per-page="20"
      :hide-default-footer="eirInstancesTotal <= 20"
      :server-items-length="eirInstancesTotal"
      :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
      :options.sync="tableOptions"
      :mobile-breakpoint="0"
      :search="search"
    >
      <template #top>
        <div class="d-flex ma-2 pt-1">
          <div class="flex-grow-1 pr-2 d-flex">
            <v-text-field
              v-model="search"
              class="pr-2"
              label="Поиск по наименованию"
              outlined
              hide-details
              dense
              clearable
              style="max-width: 500px"
            />
            <v-autocomplete
              v-model="selectedHeaders"
              class="ml-auto"
              label="Параметры (колонки) таблицы"
              :items="paramsHeaders"
              style="max-width: 320px"
              multiple
              outlined
              hide-details
              dense
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.text | truncate(30) }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  &nbsp;(+{{ selectedHeaders.length - 1 }})
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div class="flex-grow-1 text-right">
            <v-btn
              style="font-size: 12px"
              color="primary"
              elevation="10"
              large
              @click="$refs.modalInstances.show({}, 'create')"
            >
              <v-icon class="mr-1" size="18">mdi-plus</v-icon>
              Создать
            </v-btn>
          </div>
        </div>
      </template>

      <template #item.name="{ item }">
        {{ item.name }}
      </template>

      <template #item.updated_at="{ item }">
        {{ item['updated_at'] | formatDate }}
      </template>

      <template #item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-1"
              size="18"
              v-bind="attrs"
              v-on="on"
              @click="apply(item)"
            >
              mdi-cube-send
            </v-icon>
          </template>
          <span>Применить на выбранные элементы</span>
        </v-tooltip>
        <template
          v-if="
            !isDesigner || (isDesigner && item.created_by === userInfo.email)
          "
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-1"
                size="18"
                v-bind="attrs"
                v-on="on"
                @click="$refs.modalInstances.show(item.link, 'update')"
              >
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="18"
                v-bind="attrs"
                @click="removeItem(item.link)"
                v-on="on"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>

    <ModalView ref="modalView" @check="$emit('apply')" />
    <ModalInstances ref="modalInstances" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import cloneDeep from 'lodash/cloneDeep'
import mapValues from 'lodash/mapValues'
import debounce from 'lodash/debounce'
import { TAB_INSTANCES_TABLE_HEADERS } from '../../Eir/constants'

import ModalInstances from './ModalInstances'
import ModalView from './ModalView'

export default {
  name: 'TabInstances',
  components: { ModalInstances, ModalView },
  data: () => ({
    isLoading: false,
    search: '',
    selectedHeaders: [],
    tableOptions: {},
  }),
  computed: {
    ...mapGetters(['isDesigner']),
    ...mapState(['userInfo']),
    ...mapState('eirElements', ['eirElementInfo']),
    ...mapState('eirInstances', [
      'eirInstances',
      'eirInstancesOptions',
      'eirInstancesTotal',
    ]),
    computedInstances: (vm) =>
      vm.eirInstances?.map((item) => {
        const params = mapValues(item.data.params, (param) => param.value)
        const cloneItem = cloneDeep(item)
        delete cloneItem.data

        return { ...cloneItem, ...params, link: item }
      }) ?? [],
    paramsHeaders: (vm) =>
      vm.eirElementInfo?.parameters?.map((param) => ({
        text: param.name,
        value: param.id,
      })) ?? [],
    computedHeaders: (vm) => [
      {
        text: 'Наименование',
        value: 'name',
      },
      ...vm.paramsHeaders?.filter(({ value }) =>
        vm.selectedHeaders.includes(value)
      ),
      ...TAB_INSTANCES_TABLE_HEADERS,
    ],
  },
  watch: {
    paramsHeaders: {
      immediate: true,
      handler(array) {
        if (!this.selectedHeaders.length) {
          this.selectedHeaders = array.map(({ value }) => value)
        }
      },
    },
    tableOptions() {
      this.debounceFetch()
    },
    search() {
      if (this.tableOptions.page !== 1) {
        this.tableOptions.page = 1
      } else {
        this.debounceFetch()
      }
    },
  },
  beforeDestroy() {
    this.resetEirInstances()
  },
  methods: {
    ...mapMutations('eirInstances', ['resetEirInstances']),
    ...mapActions('eirInstances', ['deleteEirInstance', 'fetchEirInstances']),
    debounceFetch: debounce(function () {
      this.fetchEirInstancesWrapper()
    }, 400),
    async fetchEirInstancesWrapper() {
      this.isLoading = true
      await this.fetchEirInstances({
        options: this.tableOptions,
        filters: {
          search: this.search,
          eir_element: this.$route.params.eirId,
        },
      })
      this.isLoading = false
    },
    async removeItem(item) {
      if (confirm('Удалить экземпляр?')) {
        this.isLoading = true

        await this.deleteEirInstance(item.id)
        await this.$logActionEvent('Экземпляр удалён')

        await this.fetchEirInstancesWrapper()

        this.isLoading = false
      }
    },
    apply(item) {
      this.$refs.modalView.show(item.link)
    },
  },
}
</script>

<style scoped lang="scss">
.item-name {
  color: #e65100;

  &:hover {
    text-decoration: none;
  }
}
</style>
