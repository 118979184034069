export const SAPR_ELEMENTS_HEADERS_FIRST = [
  {
    text: 'Элемент',
    value: 'elementInfo',
    width: '100px',
  },
]

export const SAPR_ELEMENTS_HEADERS_LAST = [
  {
    text: 'Соответствие требованию',
    value: 'sync',
    sortable: false,
  },
]

export const MY_LIBRARY_COLUMNS = [
  { text: 'Наименование', value: 'title' },
  { text: 'Ссылка', value: 'url' },
  { text: '', value: 'actions', width: '60px', sortable: false },
]
