export default [
  {
    text: 'Наименование <br>/ Значение',
    value: 'param.name',
    sortable: false,
  },
  {
    text: 'Опции',
    value: 'options',
    width: '107px',
    sortable: false,
  },
  {
    text: 'Присутствие на элементах',
    value: 'param.notPresentOnAnySelectedEl',
    width: '111px',
    sortable: false,
  },
]
