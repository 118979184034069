var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"custom-table-2",attrs:{"loading-text":"Загрузка... Пожалуйста, подождите","headers":_vm.headers,"items":_vm.parameters,"loading":_vm.isLoading,"search":_vm.search,"items-per-page":20,"hide-default-footer":_vm.totalParameters <= 20,"server-items-length":_vm.totalParameters,"options":_vm.tableOptions,"mobile-breakpoint":0,"footer-props":{ itemsPerPageOptions: [20, 50, 100] },"disable-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex pt-1 mb-4"},[_c('v-text-field',{staticStyle:{"max-width":"500px"},attrs:{"label":"Поиск по наименованию","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.sortArray.length)?_c('v-btn',{staticClass:"ml-3",attrs:{"large":"","color":"primary","elevation":"10"},on:{"click":_vm.saveSort}},[_vm._v(" Сохранить сортировку ")]):_vm._e()],1)]},proxy:true},{key:"body",fn:function(props){return [_c('draggable',{attrs:{"tag":"tbody","list":props.items,"move":_vm.onMoveCallback,"disabled":_vm.designer},on:{"end":_vm.onDropCallback}},_vm._l((props.items),function(item,index){return _c('DataTableRowHandler',{key:index,attrs:{"item":item,"headers":_vm.headers},scopedSlots:_vm._u([(!_vm.designer)?{key:"item.sort",fn:function(){return [_c('v-icon',{staticClass:"cursor-sort",attrs:{"x-small":""}},[_vm._v("mdi-arrow-up-down")])]},proxy:true}:null,{key:"item.name_symbol",fn:function({
              item: { name, symbol, is_required: isRequired },
            }){return [_c('div',{staticClass:"mt-1",attrs:{"title":name}},[_vm._v(" "+_vm._s(_vm._f("truncate")(name,50))+" "),(isRequired)?_c('sup',{staticClass:"primary--text body-2",attrs:{"title":"Обязателен"}},[_vm._v(" * ")]):_vm._e()]),(symbol)?_c('div',{staticClass:"mb-1",attrs:{"title":symbol}},[_vm._v(" "+_vm._s(_vm._f("truncate")(symbol,50))+" ")]):_vm._e()]}},{key:"item.definition",fn:function({ item: { definition } }){return [_c('div',{attrs:{"title":definition}},[_vm._v(_vm._s(_vm._f("truncate")(definition,90)))])]}},{key:"item.eirs_count",fn:function({
              item: { eir_elements: eirElements, eir_count: eirCount },
            }){return [_c('DropdownMenu',{attrs:{"items":eirElements,"items-count":eirCount,"link-path":`/eir-sets/${_vm.setId}/eirs`,"append":false}})]}},{key:"item.unit_type",fn:function({ item: { unit_type: unitType } }){return [_vm._v(" "+_vm._s(_vm.getUnitTypeName(unitType))+" ")]}},{key:"item.REVIT_IS_SHARED",fn:function({
              item: { sapr_parameters: saprParameters },
            }){return [_vm._v(" "+_vm._s(_vm.getSaprParameterValue(saprParameters, 'REVIT_IS_SHARED'))+" ")]}},{key:"item.REVIT_IS_SYSTEM",fn:function({
              item: { sapr_parameters: saprParameters },
            }){return [_vm._v(" "+_vm._s(_vm.getSaprParameterValue(saprParameters, 'REVIT_IS_SYSTEM'))+" ")]}},{key:"item.updated_at",fn:function({ item: { updated_at: updatedAt } }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(updatedAt))+" ")]}},(!_vm.designer)?{key:"item.actions",fn:function({ item: prop }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$refs.modalParameter.show(prop, 'update')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Редактировать")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":prop.sapr_parameters?.length ? 'primary' : 'error',"small":""},on:{"click":function($event){return _vm.$refs.modalParameterSapr.show({
                      data: prop.sapr_parameters,
                      paramId: prop.id,
                    })}}},'v-icon',attrs,false),on),[_vm._v(" mdi-alpha-r-box-outline ")])]}}],null,true)},[_c('span',[_vm._v("Заполнить поля САПРа")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.removeParameter(prop)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Удалить")])])]}}:null],null,true)})}),1)]}}])}),_c('ModalParameter',{ref:"modalParameter",on:{"updated":function($event){return _vm.$emit('updated')}}}),_c('ModalParameterSapr',{ref:"modalParameterSapr",on:{"update-parameters-list":_vm.update}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }