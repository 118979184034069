import Model from '@/models/Model'

export default class Instance extends Model {
  static model = {
    eir_element: '',
    name: '',
    data: {
      product: {},
      params: {},
    },
    version: 1,
  }
}
