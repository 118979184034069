<script setup>
import { defineProps, ref, computed, defineEmits, watch } from 'vue'

const emit = defineEmits(['input'])
const props = defineProps({
  value: { type: Array, default: () => [] },
  items: { type: Array, default: () => [] },
  groupTitle: { type: String, default: '' },
  numberOfDisplayed: { type: Number, default: 5 },
})

const modelValue = ref([])
const numberOfDisplayed = ref(props.numberOfDisplayed)
const showMore = computed(() => numberOfDisplayed.value !== props.items.length)

watch(
  () => props.value,
  (value) => (modelValue.value = value),
  { immediate: true }
)

function showAllItems() {
  numberOfDisplayed.value = props.items.length
}
function hideUnnecessaryItems() {
  numberOfDisplayed.value = props.numberOfDisplayed
}
</script>

<template>
  <div class="group">
    <div class="group__title">{{ groupTitle }}</div>
    <v-skeleton-loader v-if="!items?.length" type="text@5" />
    <template v-else>
      <v-checkbox
        v-for="item in items.slice(0, numberOfDisplayed)"
        :key="item.key"
        v-model="modelValue"
        :label="item.label"
        :value="item.key"
        hide-details
        dense
        @change="emit('input', modelValue)"
      />
      <div
        v-if="showMore && items.length > numberOfDisplayed"
        class="group__view-all"
        @click="showAllItems"
      >
        Показать ещё
      </div>
      <div
        v-else-if="!showMore"
        class="group__view-all"
        @click="hideUnnecessaryItems"
      >
        Скрыть
      </div>
    </template>
  </div>
</template>
