export default [
  {
    text: 'Полное / Краткое наименование',
    value: 'name_symbol',
  },
  {
    text: 'Описание / Определение',
    value: 'definition',
  },
  {
    text: 'Кол-во требований c параметром',
    value: 'eirs_count',
    style: 'width: 93px;',
  },
  {
    text: 'Единицы измерения',
    value: 'unit_type',
  },
  {
    text: 'Общий',
    value: 'REVIT_IS_SHARED',
  },
  {
    text: 'Код параметра (системный)',
    value: 'REVIT_IS_SYSTEM',
  },
  {
    text: 'Дата изменения',
    value: 'updated_at',
  },
]
