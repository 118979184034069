<template>
  <v-dialog
    v-model="dialog"
    width="596"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" color="primary" indeterminate />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            Применить {{ name }}
            <v-spacer />
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <SelectSections
            class="mx-4"
            persistent-hint
            hint="Оставьте пустым, если нужны все разделы"
            :delay="0"
            @input="updateSelectedSections"
          />

          <v-card-text>
            <v-data-table
              class="custom-table"
              :headers="MODAL_VIEW_TABLE_HEADERS"
              :items="filteredParams"
              :items-per-page="-1"
              :mobile-breakpoint="0"
              hide-default-footer
            >
              <template #header.param.name="{ header }">
                <span v-html="header.text" />
              </template>

              <template #item.param.name="{ item }">
                <div>{{ item.name }}</div>
                <div
                  v-if="item.value"
                  :class="[
                    'item-value',
                    {
                      problem:
                        item.notPresentOnAnySelectedEl &&
                        !item.caCreaten &&
                        getParamCreatingStatus(eirId, item.name) !== 3,
                    },
                  ]"
                >
                  {{ getItemDescription(item) }}
                </div>
              </template>

              <template #item.options="{ item }">
                <OptionsSlider
                  :key="item.name"
                  class="option-slider"
                  :param="item"
                  :items.sync="params"
                  :disabled="
                    item.notPresentOnAnySelectedEl &&
                    getParamCreatingStatus(eirId, item.name) !== 3
                  "
                />
              </template>

              <template #item.param.notPresentOnAnySelectedEl="{ item }">
                <template
                  v-if="
                    item.notPresentOnAnySelectedEl &&
                    getParamCreatingStatus(eirId, item.name) !== 3
                  "
                >
                  <v-btn
                    v-if="item.canCreate"
                    :color="
                      getParamCreatingStatus(eirId, item.name) === 0
                        ? 'error'
                        : 'warning'
                    "
                    outlined
                    :disabled="item.readOnly || isCreating"
                    :loading="getParamCreatingStatus(eirId, item.name) === 2"
                    @click="create(item)"
                  >
                    Создать
                  </v-btn>
                  <v-btn
                    v-else
                    :color="
                      getParamCreatingStatus(eirId, item.name) === 0
                        ? 'error'
                        : 'warning'
                    "
                    outlined
                    :disabled="item.readOnly"
                    title="Перейти на страницу редактирования параметров"
                    :to="`/eir-sets/${setId}?tab=1`"
                  >
                    Заполнить
                  </v-btn>
                </template>
                <div v-else class="text-center">
                  <v-icon :disabled="item.readOnly" color="primary">
                    mdi-check-all
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-card-text>

          <SaveCloseButtons
            :disabled="isLoading"
            disable-btn-save
            class="mx-2"
            @close="dialog = false"
          >
            <v-btn
              class="ml-2 elevation-10 flex"
              color="primary"
              type="submit"
              :disabled="
                isLoading || !filteredParams.length || paramsIsNotPresent
              "
              x-large
            >
              Записать на {{ btnText }} элементы
            </v-btn>
          </SaveCloseButtons>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

import {
  checkIfCanCreateParam,
  checkIfCustomReadonly,
  checkIfShared,
} from '@/utils/otherHelpers'

import SaveCloseButtons from '@/components/SaveCloseButtons'
import SelectSections from '@/components/SelectSections'

import OptionsSlider from './OptionsSlider'

import { MODAL_VIEW_TABLE_HEADERS } from '@/views/Eir/constants'

export default {
  name: 'ModalView',
  components: { SelectSections, SaveCloseButtons, OptionsSlider },
  data: () => ({
    name: '',
    params: [],
    dialog: false,
    MODAL_VIEW_TABLE_HEADERS,
    selectedSections: [],
  }),
  computed: {
    ...mapState('eirElements', ['eirElementInfo']),
    ...mapState('sections', ['sections']),
    ...mapState('revit', [
      'selectedSaprElements',
      'applyStatus',
      'createStatus',
    ]),
    ...mapGetters('revit', [
      'checkIfParamIsReadonly',
      'checkIfParamIsNotPresent',
      'getParamCreatingStatus',
      'checkIfSomeParamWasCreated',
    ]),
    btnText: (vm) => (!vm.selectedSaprElements?.length ? 'все' : 'выбранные'),
    isLoading: (vm) => vm.applyStatus === 2,
    isCreating: (vm) =>
      Object.entries(vm.createStatus).some(([, status]) => status === 2),
    filteredParams: (vm) =>
      vm.selectedSections?.length
        ? vm.params.filter((param) =>
            vm.selectedSections.every((id) => param.sections.includes(id))
          )
        : vm.params,
    paramsIsNotPresent: (vm) =>
      vm.filteredParams?.every(
        ({ notPresentOnAnySelectedEl }) => notPresentOnAnySelectedEl
      ) && !vm.checkIfSomeParamWasCreated(vm.eirId),
    setId: (vm) => vm.eirElementInfo.eir_set,
    eirId: (vm) => vm.eirElementInfo.id,
  },
  watch: {
    applyStatus(value) {
      if (value !== 3 && value !== 0) return

      this.dialog = false
    },
    dialog(newVal) {
      if (newVal) return

      setTimeout(this.reset, 500)
    },
  },
  mounted() {
    this.fetchSections({ eir_set__in: this.setId })
  },
  methods: {
    ...mapActions('sections', ['fetchSections']),
    ...mapActions('revit', ['applyParamsRevit', 'createParamRevit']),
    ...mapMutations('revit', ['resetCreateStatus']),
    show(eirInstance) {
      this.name = eirInstance.name

      const paramValues = cloneDeep(Object.values(eirInstance.data.params))
      this.params = paramValues.map(this.prepareParam)

      this.dialog = true
    },
    submit() {
      this.applyParamsRevit({
        parameters: this.params.filter((param) => param.writeDown),
        eirElementId: this.$route.params.eirId,
      })
    },
    updateSelectedSections(options, array) {
      this.selectedSections = array
    },
    reset() {
      this.name = ''
      this.params = []
      this.selectedSections = []
      this.resetCreateStatus(this.eirId)
      this.$emit('check')
    },
    getItemDescription(item) {
      const isCreated = this.getParamCreatingStatus(this.eirId, item.name) === 3
      let description = null

      if (item.readOnly) description = 'Только для чтения'
      else if (item.notPresentOnAnySelectedEl && !isCreated) {
        description = 'Отсутствует на элементе'

        if (!item.isShared) description = 'Параметр не общий, создать нельзя'
        else if (!item.canCreate) {
          description = 'Нет необходимых САПР-полей, создать нельзя'
        }
      }

      return description ?? item.value
    },
    create(parameter) {
      this.createParamRevit({ parameter, eirElementId: this.eirId })
    },
    prepareParam(param) {
      const readOnly =
        checkIfCustomReadonly(param.param) ||
        this.checkIfParamIsReadonly(this.eirId, param.param.name)

      const writeDown = !readOnly && (param.value === 0 ? true : !!param.value)

      return {
        name: param.param.name,
        value: param.value,
        saprFields: param.param.sapr_parameters,
        sections: param.param.sections.map(({ id }) => id),
        overwriteExistingValue: false,
        writeDown,
        readOnly,
        isShared: checkIfShared(param.param),
        canCreate: checkIfCanCreateParam(param.param),
        notPresentOnAnySelectedEl: this.checkIfParamIsNotPresent(
          this.eirId,
          param.param.name
        ),
      }
    },
  },
}
</script>

<style scoped lang="scss">
.option-slider {
  padding-top: 12px;

  &::v-deep {
    .v-slider__thumb {
      left: -3px;
    }

    .v-slider--horizontal {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
}

.option-checkbox {
  transform: scale(0.8);
  transform-origin: 100% 50%;
}

.item-value {
  opacity: 0.9;
  font-size: 75%;
  line-height: 1.2;

  &.problem {
    color: #ff5252;
  }
}

.custom-table::v-deep .v-data-table-header th {
  font-size: 13px !important;
  line-height: 1.2;
}

.value-changes {
  border-bottom: 1px dashed;
}

.custom-table::v-deep {
  .v-input--selection-controls__input {
    margin-right: 0;
  }

  .v-slider__thumb::before {
    left: -5px;
    top: -5px;
    width: 22px;
    height: 22px;
  }

  .v-slider__thumb::after {
    width: 30px;
    height: 30px;
  }
}
</style>
