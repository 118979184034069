<script setup>
import { ref, defineProps, defineEmits, computed, watchEffect } from 'vue'

const props = defineProps({
  value: { type: [String, Number], default: null },
  param: { type: Object, default: () => ({}) },
  formData: { type: Object, default: () => ({}) },
})
const emit = defineEmits(['input'])
const value = computed({
  get: () => props.value,
  set: (value) => emit('input', value),
})

const pickItems = computed(() => {
  const setId = Object.values(props.formData).reduce(
    (array, { productParam, value }) => {
      if (productParam && value) {
        array.push(productParam.id)
        if (productParam.variants) {
          array.push(
            ...(productParam.variants.find((item) => {
              return item.name === value
            })?.related ?? [])
          )
        }
      }
      return array
    },
    []
  )

  const result = (props.param?.variants ?? []).filter(({ id }) =>
    setId.includes(id)
  )
  return !result?.length ? props.param?.variants : result
})
const pickIntervalItem = computed(() =>
  pickItems.value?.length
    ? pickItems.value[0]
    : props.param?.variants?.[0] ?? null
)

let selectedInterval = ref(null)
let selectedValue = props.value

// Отслеживайте изменения в selectedInterval и обновляйте selectedValue соответственно
watchEffect(() => {
  if (selectedInterval.value) {
    selectedInterval.value = props.param.variants.find(
      (item) => item.id === selectedInterval.value
    )
    selectedValue = Math.min(
      Math.max(selectedValue, selectedInterval.value.from),
      selectedInterval.value.to
    )
    emit('input', selectedValue)
  }
})

function getLabelForNumberField(param) {
  const array = []
  if (!isNaN(Number(param.from))) array.push(`Число от ${param.from}`)
  if (!isNaN(Number(param.to))) {
    if (array.length) {
      array.push(`до ${param.to}`)
    } else {
      array.push(`Число до ${param.to}`)
    }
  }
  if (!isNaN(Number(param.step))) {
    if (array.length) {
      array.push(`с шагом ${param.step}`)
    } else {
      array.push(`Число с шагом ${param.step}`)
    }
  }
  return array.join(' ')
}
</script>

<template>
  <div>
    <div v-if="param.interval" class="d-flex align-center">
      <div class="mr-1 slider-container">
        <div class="slider-label">
          {{ getLabelForNumberField(param) }}
        </div>
        <v-slider
          v-model="value"
          :min="param.from"
          :max="param.to"
          :step="param.step"
          hide-details
          dense
        />
      </div>
      <v-text-field
        v-model="value"
        class="slider-number-field"
        type="number"
        :min="param.from"
        :max="param.to"
        :step="param.step"
        hide-details
        outlined
        dense
      />
    </div>
    <div v-else-if="param.pickInterval" class="d-flex align-center">
      <v-select
        v-if="param && param.pickInterval"
        v-model="selectedInterval"
        :items="pickItems"
        item-value="id"
        item-text="to"
        dense
        hide-details
        outlined
        placeholder="Выберите значение"
      />

      <div v-if="selectedInterval" class="mr-1 slider-container">
        <div class="slider-label">
          {{ getLabelForNumberField(selectedInterval) }}
        </div>
        <v-slider
          v-model="value"
          :min="selectedInterval.from"
          :max="selectedInterval.to"
          :step="selectedInterval.step"
          hide-details
          dense
        />
      </div>
      <v-text-field
        v-model="value"
        class="slider-number-field"
        type="number"
        :min="pickIntervalItem.from"
        :max="pickIntervalItem.to"
        :step="pickIntervalItem.step"
        hide-details
        outlined
        dense
      />
    </div>
    <v-select
      v-else-if="param.pick"
      v-model="value"
      :items="pickItems"
      item-value="name"
      item-text="name"
      dense
      hide-details
      outlined
      placeholder="Выберите значение"
    />
    <v-text-field
      v-else
      v-model="value"
      dense
      hide-details
      outlined
      placeholder="Введите значение"
    />
  </div>
</template>

<style lang="scss" scoped>
.slider-label {
  margin-bottom: -10px;
}

.slider-container {
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: -8px;
}

.slider-number-field {
  max-width: 100px;
}
</style>
