export default {
  Element: {
    _: [
      { value: 'Included', name: 'Включая' },
      { value: 'Excluded', name: 'Исключая' },
    ],
    Name: [
      // TODO: убрать, если не нужно
      { value: '=', name: 'Строго равно (=)' },
      { value: '≠', name: 'Строго не равно (≠)' },
      { value: 'Contains', name: 'Содержит' },
      { value: 'Does Not Contains', name: 'Не содержит' },
    ],
  },
  Category: {
    _: [
      { value: 'Included', name: 'Включая' },
      { value: 'Excluded', name: 'Исключая' },
    ],
    Name: [
      { value: '=', name: 'Строго равно (=)' },
      { value: '≠', name: 'Строго не равно (≠)' },
      { value: 'Contains', name: 'Содержит' },
      { value: 'Does Not Contains', name: 'Не содержит' },
    ],
  },
  Parameter: [
    { value: '=', name: 'Строго равно (=)' },
    { value: '≠', name: 'Строго не равно (≠)' },
    { value: '>', name: 'Больше (>)' },
    { value: '<', name: 'Меньше (<)' },
    { value: '>=', name: 'Больше либо равно (>=)' },
    { value: '<=', name: 'Меньше либо равно (<=)' },
    { value: 'Contains', name: 'Содержит' },
    { value: 'Does Not Contains', name: 'Не содержит' },
    { value: 'Duplicated', name: 'Дублировано' },
    { value: 'BeginsWith', name: 'Со значением, начинающимся с' },
    { value: 'EndsWith', name: 'Со значением, заканчивающимся на' },
    { value: 'NotBeginsWith', name: 'Со значением, НЕ начинающимся с' },
    { value: 'NotEndsWith', name: 'Со значением, НЕ заканчивающимся на' },
    { value: 'Excluded', name: 'Исключая' },
  ],
}
