<script setup>
import {
  reactive,
  computed,
  defineEmits,
  watch,
  onMounted,
  defineExpose,
} from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { useStore } from '@/store'

import CheckboxGroup from '@/components/ProjectEir/CheckboxGroup.vue'
import TextFieldsGroup from '@/components/ProjectEir/TextFieldsGroup.vue'

defineExpose({ setManufacturer, reset })

const emit = defineEmits(['change'])
const store = useStore()

const formModel = {
  searchString: '',
  paramList: [],
  manufacturerList: [],
  price: {
    from: null,
    to: null,
  },
}
const formData = reactive(cloneDeep(formModel))

const paramList = computed(() =>
  prepareCheckboxItems(store.state.catalogs.paramList)
)
const manufacturerList = computed(() =>
  prepareCheckboxItems(store.state.catalogs.manufacturerList)
)

watch(
  () => formData,
  () => {
    const data = cloneDeep(formData)

    if (!data.searchString) data.searchString = ''
    if (data.paramList?.length)
      data.paramList = data.paramList.filter(
        ({ value }) => value !== null && value !== ''
      )

    emit('change', data)
  },
  { deep: true }
)
onMounted(() => {
  store.dispatch('catalogs/fetchParamList')
  store.dispatch('catalogs/fetchManufacturerList')
})

function prepareCheckboxItems(array) {
  return array.filter((v) => !!v).map((label) => ({ key: label, label }))
}
function setManufacturer(name) {
  formData.manufacturerList = [name]
}
function reset() {
  Object.assign(formData, cloneDeep(formModel))
}
</script>

<template>
  <div class="filter">
    <div class="group">
      <div class="group__title">Поиск по наименованию</div>
      <v-text-field
        v-model.trim="formData.searchString"
        class="mb-5"
        outlined
        hide-details
        dense
        clearable
      />
    </div>
    <TextFieldsGroup
      v-model="formData.paramList"
      group-title="Параметры"
      :items="paramList"
    />
    <CheckboxGroup
      v-model="formData.manufacturerList"
      group-title="Производители"
      :items="manufacturerList"
    />
    <div class="group">
      <div class="group__title">Цена</div>
      <div class="d-flex align-center">
        <span class="mr-2">от</span>
        <v-text-field
          v-model.number="formData.price.from"
          type="number"
          step="0.01"
          outlined
          hide-details
          dense
          class="mr-2"
        />
        <span class="mr-2">до</span>
        <v-text-field
          v-model.number="formData.price.to"
          type="number"
          step="0.01"
          outlined
          hide-details
          dense
        />
      </div>
    </div>
    <div class="group">
      <div class="group__title">Премиум производители</div>
      <div class="logo-list">
        <div class="logo-item">
          <img src="@/assets/logos/bergauf.svg" alt="Bergauf" title="Bergauf" />
        </div>
        <div class="logo-item">
          <img src="@/assets/logos/fensys.svg" alt="FENSYS" title="FENSYS" />
        </div>
        <div class="logo-item">
          <img src="@/assets/logos/grent.svg" alt="GRENT" title="GRENT" />
        </div>
        <div class="logo-item">
          <img src="@/assets/logos/hobbika.svg" alt="Хоббика" title="Хоббика" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.logo-list {
  margin: -5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.logo-item {
  width: 25%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
}

:deep(.group) {
  color: #192724;
  margin-bottom: 15px;

  .group__title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .group__view-all {
    color: #4eaca1;
    font-size: 14px;
    cursor: pointer;
    margin-top: 8px;
  }

  .group__scroll {
    overflow-x: auto;
    max-height: 190px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bababa;
      border-radius: 4px;
      border: 0 none #ffffff;
    }
  }
}
</style>
