<template>
  <div class="d-flex">
    <div v-if="!isSandbox" class="flex-grow-1">
      <h3 class="mb-1">Фильтр элементов в САПРе</h3>
    </div>
    <div class="flex-grow-1 text-right">
      <v-btn
        color="primary"
        elevation="10"
        :disabled="
          !connected || !selectedFile || checking || disabled || loading
        "
        :loading="checking"
        height="40"
        @click.native.stop="
          !conditions.length
            ? checkEir(eirElement)
            : checkEir(eirElement, { conditions })
        "
      >
        <v-icon class="mr-2">{{
          !isSandbox
            ? 'mdi-clipboard-check-multiple-outline'
            : 'mdi-filter-outline'
        }}</v-icon>
        {{ !isSandbox ? 'Проверить на соответствие' : 'Проверить фильтр' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FilterHeader',
  props: {
    eirElement: {
      type: Object,
      default: () => ({}),
    },
    isSandbox: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    conditions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('revit', ['syncStatus', 'activeDocument']),
    ...mapState('filters', ['currentFilter']),
    ...mapGetters('revit', ['getCheckStatusById']),
    connected: (vm) => vm.syncStatus === 3,
    checking: (vm) => vm.getCheckStatusById(vm.eirElement.id) === 2,
    selectedFile: (vm) => vm.activeDocument?.filePath ?? '',
  },
  beforeDestroy() {
    if (this.isSandbox) this.resetStateOfTestChecks()
  },
  methods: {
    ...mapMutations('revit', ['resetStateOfTestChecks']),
    ...mapActions('revit', ['checkRevitProject', 'filterCheck']),
    ...mapActions('parameters', ['fetchParams']),
    async checkEir(eirElement, revitFilter = this.currentFilter) {
      const conditionsInTheElement = eirElement.revit_filter?.conditions ?? []
      const conditions = revitFilter?.conditions?.length
        ? revitFilter.conditions
        : conditionsInTheElement

      if (!conditions?.length) {
        this.$logger(
          'warn',
          '[revit/FilterHeaderCheckRevit]',
          'Условия фильтра пусты!'
        )

        return
      }

      try {
        const parameters =
          (await this.fetchParams({
            filters: { eir: eirElement.id },
            onlyValue: true,
          })) ?? []

        const options = {
          projectId: +(this.$route.params.projectId ?? 0),
          eirElementId: eirElement.id,
          filter: {
            conditions,
            parameters,
          },
        }

        if (this.isSandbox) await this.filterCheck(options)
        else await this.checkRevitProject(options)
      } catch (e) {
        this.$logger('error', '[revit/FilterHeaderCheckRevit]', e)
      }
    },
  },
}
</script>
