<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import { formatPrice } from '@/utils/formatPrice'

import ModalProductInquiry from '@/components/ProjectEir/ModalProductInquiry.vue'

const emit = defineEmits(['click', 'selectManufacturer', 'showParamList'])
const props = defineProps({
  product: {
    type: Object,
    default: () => ({
      name: null,
      img: null,
      status: null,
      price: null,
      oldPrice: null,
      url: null,
      manufacturer: {
        name: null,
        url: null,
        logo: null,
        vip: false,
        verified: false,
      },
      parameters: [],
    }),
  },
})

const productInquiryRef = ref(false)

const dialogImage = ref(false)
const like = ref(false)
const manufacturer = computed(() => props.product?.manufacturer ?? null)
console.log(props.product?.parameters)
const parameters = computed(() => {
  console.log('params:', Array.isArray(props.product?.parameters))
  if (Array.isArray(props.product?.parameters)) {
    return (
      props.product?.parameters
        ?.filter(
          (item) =>
            item.name && item.value && item.name.trim() !== 'ID элемента'
        )
        ?.slice(0, 6) ?? []
    )
  } else return null
})
const status = computed(() => props.product?.status ?? null)
const statusText = computed(() => {
  const obj = {
    premium: 'Премиум',
    perfect: 'Идеально',
    verified: 'Проверен',
  }
  return obj?.[status.value] ?? null
})
const statusIcon = computed(() => {
  const obj = {
    premium: 'mdi-star',
    perfect: 'mdi-trophy-variant',
    verified: 'mdi-check',
  }
  return obj?.[status.value] ?? null
})
const price = computed(() =>
  props.product?.price ? formatPrice(props.product?.price) : null
)
const oldPrice = computed(() =>
  props.product?.oldPrice ? formatPrice(props.product?.oldPrice) : null
)
</script>

<template>
  <div
    class="product"
    :class="{ [`product--${status}`]: status }"
    @click="emit('click', props.product)"
  >
    <ModalProductInquiry ref="productInquiryRef" />
    <v-dialog v-if="product?.img" v-model="dialogImage" max-width="967">
      <div style="position: relative">
        <v-icon
          class="modal-close"
          title="Закрыть"
          @click="dialogImage = false"
        >
          mdi-close
        </v-icon>
        <img style="display: block; width: 100%" :src="product.img" alt />
      </div>
    </v-dialog>
    <div class="product__left">
      <div
        class="product__image"
        :style="product?.img ? `background-image: url('${product.img}');` : ''"
        @click.stop="dialogImage = true"
      >
        <v-icon v-if="product?.img"> mdi-magnify-plus-outline </v-icon>
      </div>
      <div class="product__left__text">
        <div v-if="product?.name" class="product__title">
          {{ product.name }}
        </div>
        <div class="product__buttons">
          <!--          <v-btn-->
          <!--            class="product__like mr-2"-->
          <!--            x-small-->
          <!--            elevation="0"-->
          <!--            :color="like ? 'primary' : null"-->
          <!--            @click.stop="like = !like"-->
          <!--          >-->
          <!--            <v-icon dark small>-->
          <!--              {{ like ? 'mdi-heart' : 'mdi-heart-outline' }}-->
          <!--            </v-icon>-->
          <!--          </v-btn>-->
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                class="product__more"
                x-smalll
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click.stop
              >
                Узнать больше
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item disabled @click="() => {}">
                <v-list-item-title>Уточнить параметры</v-list-item-title>
              </v-list-item>
              <v-list-item disabled @click="() => {}">
                <v-list-item-title>
                  Запросить условия поставки
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :disabled="!manufacturer.email"
                @click="
                  productInquiryRef.show(manufacturer.email, product.name)
                "
              >
                <v-list-item-title> Запрос продукта </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <div class="product__center">
      <div v-if="parameters" class="product__parameters">
        <div
          v-for="{ name, value } in parameters"
          :key="name"
          class="product__attribute"
        >
          <div class="product__attribute__title" :title="name">
            {{ name | truncate(12) }}
          </div>
          <div class="product__attribute__value" :title="value">
            {{ value | truncate(12) }}
          </div>
        </div>
      </div>
      <div class="product__links">
        <div
          class="product__show-params"
          @click.stop="emit('showParamList', product)"
        >
          Все параметры
        </div>
        <div v-if="manufacturer" class="product__links__company">
          <div
            v-if="manufacturer?.logo"
            class="product__links__company__logo"
            :style="`background-image: url('${manufacturer.logo}');`"
          />
          <div>{{ manufacturer.name }}</div>
          <v-icon
            v-if="manufacturer?.verified"
            class="ml-1 product__icon"
            size="18"
            color="primary"
          >
            mdi-check-decagram
          </v-icon>
          <v-icon
            v-if="manufacturer?.vip"
            class="ml-1 product__icon"
            size="18"
            color="#F3B640"
          >
            mdi-chess-queen
          </v-icon>
        </div>
        <a
          v-if="product?.url"
          class="product__links__website"
          :class="{ [`product__links__website--${status}`]: status }"
          target="_blank"
          :href="product.url"
          @click.stop
        >
          <v-icon
            class="ml-1 product__icon"
            size="18"
            :color="status === 'premium' ? '#F3B640' : 'primary'"
          >
            mdi-open-in-new
          </v-icon>
          Ссылка
        </a>
        <a
          class="product__links__catalog"
          target="_blank"
          @click.stop="emit('selectManufacturer', manufacturer.name)"
        >
          <v-icon
            class="ml-1 product__icon"
            size="18"
            color="rgba(25, 39, 36, 0.55)"
          >
            mdi-web
          </v-icon>
          Каталог
        </a>
      </div>
    </div>
    <div class="product__right">
      <div
        v-if="status && statusText && statusIcon"
        class="product__status mb-2"
        :class="{ [`product__status--${status}`]: status }"
      >
        <v-icon
          class="product__icon mr-1"
          size="18"
          :color="
            status === 'premium'
              ? '#F3B640'
              : status === 'perfect'
              ? 'primary'
              : null
          "
        >
          {{ statusIcon }}
        </v-icon>
        {{ statusText }}
      </div>
      <div v-if="price" class="product__price mb-2">
        {{ price }}
        <span class="product_unit ml-1">руб./кг</span>
      </div>
      <div v-if="oldPrice" class="product__old-price">
        {{ oldPrice }}
        <span class="product_unit">руб./кг</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
  opacity: 0.8;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  &:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.product {
  // если меняется высота, то изменить в ModalManufacturersCatalogs для виртуального скрола
  height: 112px;
  padding: 15px;
  display: flex;
  cursor: pointer;
  user-select: none;
  // если меняется отступ, то изменить в ModalManufacturersCatalogs для виртуального скрола
  margin-bottom: 8px;
  border-radius: 10px;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  &:hover {
    border-color: #4eaca1;
  }
  &--premium {
    border-color: rgba(243, 182, 64, 0.5);
    background-color: rgba(243, 182, 64, 0.05);
    &:hover {
      border-color: #f3b640;
    }
  }
  &--perfect {
    border-color: rgba(78, 172, 161, 0.5);
    background-color: rgba(61, 204, 187, 0.05);
  }

  &__show-params {
    line-height: 1;
    cursor: pointer;
    margin-right: 15px;
    border-bottom: 1px dashed #4eaca1;
    &:hover {
      border-bottom-color: inherit;
    }
  }

  &__right {
    text-align: right;
  }

  &__old-price {
    color: #192724;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    color: rgba(25, 39, 36, 0.55);
    text-decoration: line-through;
  }

  &__price {
    display: flex;
    color: #192724;
    line-height: 1;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
    align-items: flex-end;
  }

  &_unit {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 2px;
  }

  &__status {
    height: 28px;
    display: flex;
    padding: 4px 8px;
    user-select: none;
    border-radius: 8px;
    align-items: center;
    background-color: #fafafa;
    &--premium {
      color: #f3b640;
      background-color: rgba(243, 182, 64, 0.05);
    }
    &--perfect {
      color: #4eaca1;
      background-color: rgba(61, 204, 187, 0.05);
    }
  }

  &__icon {
    margin-top: -1px;
  }

  &__links {
    display: flex;
    align-items: center;

    &__company {
      display: flex;
      font-size: 14px;
      margin-right: 15px;
      align-items: center;

      &__logo {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }

    &__website {
      color: #4eaca1;
      font-size: 14px;
      margin-right: 15px;
      text-decoration: none;
      &--premium {
        color: #f3b640;
      }
    }

    &__catalog {
      font-size: 14px;
      text-decoration: none;
      color: rgba(25, 39, 36, 0.55);
    }
  }

  &__left {
    display: flex;
    margin-right: 30px;

    &__text {
      width: 210px;
    }
  }

  &__image {
    width: 64px;
    height: 76px;
    margin-right: 15px;
    background-color: #eee;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__title {
    height: 40px;
    color: #192724;
    font-size: 12px;
    overflow: hidden;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &__buttons {
    display: flex;
    button {
      border-radius: 4px;
      height: 28px !important;
      font-size: 12px !important;
      text-transform: uppercase !important;
    }
  }

  &__center {
    width: 100%;
    margin-right: 20px;
  }

  &__parameters {
    display: flex;
    margin-bottom: 7px;
    padding-bottom: 5px;
    gap: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__attribute {
    margin-right: 5px;

    &__title {
      height: 24px;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 3px;
      color: rgba(25, 39, 36, 0.55);
    }

    &__value {
      line-height: 1;
      font-size: 13px;
      height: 26px;
    }
  }
}
</style>
