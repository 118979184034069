<template>
  <header>
    <div class="mb-2">
      <v-progress-linear
        :buffer-value="checking ? 90 : 100"
        :value="progressValue"
        height="20"
        stream
        striped
        color="primary"
      >
        <span class="body-2 font-weight-black">
          {{ `Соответствует на ${progressValue ?? '—'}%` }}
        </span>
      </v-progress-linear>
    </div>

    <div class="d-flex flex-column">
      <div class="d-flex flex-column flex-lg-row">
        <div
          class="mr-2 mb-2 flex-grow-1"
          :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 50%;' : ''"
        >
          <div class="d-flex align-center mb-1 pr-2">
            <h1>{{ title }}</h1>
            <slot name="actions" />
          </div>

          <div class="d-flex flex-row align-center">
            <span class="caption mr-1">{{ caption }}</span>
            <v-chip small label outlined color="primary" class="mr-1">
              <span class="font-weight-bold">Изменено:&nbsp;</span>
              {{ updatedAt | formatDate }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'DashboardEirHeader',
  props: {
    title: {
      type: String,
      default: null,
    },
    caption: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: null,
    },
    checking: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: null,
    },
    selectedFile: {
      type: String,
      default: null,
    },
  },
  computed: {
    progressValue: (vm) => (vm.selectedFile ? vm.progress : null),
  },
}
</script>
