<template>
  <v-dialog
    v-model="dialog"
    width="476"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-card-title class="pb-4">
          Сохранение шаблона фильтра
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model.trim="name"
            label="Название шаблона*"
            :rules="[rules.required]"
            outlined
            dense
          />
        </v-card-text>

        <SaveCloseButtons :disabled="isLoading" @close="dialog = false" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import SaveCloseButtons from '@/components/SaveCloseButtons'

import rules from '@/mixins/rules'

export default {
  name: 'ModalFilterTemplate',
  components: { SaveCloseButtons },
  mixins: [rules],
  data: () => ({
    valid: true,
    name: '',
    dialog: false,
  }),
  computed: {
    ...mapState('filters', ['templateFilterStatus']),
    isLoading: (vm) => vm.templateFilterStatus === 2,
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
    isLoading(val) {
      if (!val) this.dialog = false
    },
  },
  methods: {
    show() {
      this.dialog = true
    },
    async submit() {
      if (!this.$refs.form.validate()) return

      this.$emit('save', this.name)
    },
  },
}
</script>
