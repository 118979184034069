export default [
  {
    text: 'Последнее изменение',
    value: 'updated_at',
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right',
    width: '95px',
  },
]
